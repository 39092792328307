import { BaseDataTableInput, BaseTd } from '../../base/BaseDataTable'
import { classNames } from '../../utils'
export default function ColumnFilterCell({
    field,
    className,
    style,
    filterElement,
    filterElementClassName,
    filterElementStyle,
    filterPlaceholder,
    value,
    onChangeFilter,
}) {
    const handleChangeFilter = ({ target }) => {
        onChangeFilter(target.name, target.value)
    }
    const filterCallback = (value) => {
        onChangeFilter(field, value)
    }
    return (
        <BaseTd className={className} style={style}>
            {filterElement ? (
                typeof filterElement === 'function' ? (
                    filterElement({ value, filterCallback })
                ) : (
                    filterElement
                )
            ) : (
                <BaseDataTableInput
                    name={field}
                    type='text'
                    placeholder={filterPlaceholder}
                    value={value}
                    onChange={handleChangeFilter}
                    className={classNames('dtf-input', filterElementClassName)}
                    style={filterElementStyle}
                />
            )}
        </BaseTd>
    )
}
