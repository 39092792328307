import { useRef, useEffect, useState, useMemo } from 'react'
import * as pdfjs from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

// Configura la ubicación del worker de PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

export default function PdfViewer({ fileUrl, percentage = '100%' }) {
    const pdfPercentage = useMemo(() => {
        let _percentage = 100
        if (typeof percentage === 'string' && percentage.endsWith('%')) {
            const value = parseFloat(percentage.slice(0, -1))
            if (value >= 50 && value <= 250) _percentage = value
        }
        return `${_percentage}%`
    }, [percentage])
    const [pdf, setPdf] = useState(null)
    const [pages, setPages] = useState([])
    const containerRef = useRef(null)

    useEffect(() => {
        const loadPdf = async () => {
            try {
                const loadingTask = pdfjs.getDocument(fileUrl)
                const pdf = await loadingTask.promise
                setPdf(pdf)

                // Cargar las páginas del PDF
                const numPages = pdf.numPages
                const pagesArray = []
                for (let i = 1; i <= numPages; i++) {
                    pagesArray.push(i)
                }
                setPages(pagesArray)
            } catch (error) {
                console.error('Error loading PDF:', error)
            }
        }

        loadPdf()
    }, [fileUrl])

    useEffect(() => {
        const renderPages = async () => {
            if (pdf) {
                const canvasElements = containerRef.current.querySelectorAll('canvas')
                canvasElements.forEach((canvas) => {
                    containerRef.current.removeChild(canvas)
                })

                for (const pageNumber of pages) {
                    const page = await pdf.getPage(pageNumber)
                    const viewport = page.getViewport({ scale: 2 })
                    const canvas = document.createElement('canvas')
                    const context = canvas.getContext('2d')

                    canvas.height = viewport.height
                    canvas.width = viewport.width
                    canvas.style.border = '1px solid #dee2e6'
                    canvas.style.boxShadow = '1px 1px 3px 0 #00000040'
                    containerRef.current.appendChild(canvas)

                    const renderContext = {
                        canvasContext: context,
                        viewport,
                    }
                    await page.render(renderContext).promise
                }
            }
        }

        renderPages()
    }, [pdf, pages])

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflowY: 'auto',
            }}
        >
            <div style={{ width: pdfPercentage, margin: 'auto' }}>
                <div
                    ref={containerRef}
                    style={{
                        height: '100%',
                        width: '100%',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '1rem 1.5rem',
                        gap: '1rem',
                    }}
                ></div>
            </div>
        </div>
    )
}
