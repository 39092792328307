export default function SortAlphaDown(props) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M5 14V2M5 14L8.5 10M5 14L1.5 10M14.5 14H10.5L14.5 9H10.5M14.5 7L14.1 6M10.5 7L10.9 6M10.9 6L12.5 2L14.1 6M10.9 6H14.1'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
