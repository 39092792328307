import { classNames, joinStyles, propFromName } from '../../utils'
import { BaseDataTableCheckbox, BaseDataTableRadio, BaseTd, BaseTr } from '../../base/BaseDataTable'
export default function DataTableRow({
    innerRef,
    headers,
    selectionMode,
    selectionOn,
    onKeyDown,
    value,
    rowIndex,
    rowSpan,
    rowSpanField,
    selectable,
    disabled,
    selected,
    selectableIndex,
    onSelect,
    className,
    style,
}) {
    const handleSelect = () => {
        onSelect(value, selected)
    }
    const handleEnter = (e) => {
        e.key === 'Enter' && handleSelect()
    }
    return (
        <BaseTr
            ref={selectionOn === 'row' ? innerRef : undefined}
            className={className}
            style={style}
            {...(selectable &&
                selectionOn === 'row' && {
                    tabIndex: 0,
                    onClick: handleSelect,
                    onKeyUp: handleEnter,
                    onKeyDown: (e) => onKeyDown(e, selectableIndex),
                })}
        >
            {headers.map(({ column: col }, index) => {
                const field = col.field ?? ''
                if (rowSpanField && rowSpanField === field && rowSpan === 0) {
                    return null
                }
                const data = propFromName(value, field)
                return (
                    <BaseTd
                        key={col.selector ? 'selector' : (col.id ?? index)}
                        className={classNames(
                            col.className,
                            typeof col.cellClassName === 'function'
                                ? col.cellClassName(value, { rowIndex, disabledRow: disabled })
                                : col.cellClassName,
                        )}
                        style={joinStyles(
                            col.style,
                            typeof col.cellStyle === 'function'
                                ? col.cellStyle(value, { rowIndex, disabledRow: disabled })
                                : col.cellStyle,
                        )}
                        {...(rowSpan > 1 && rowSpanField === field && { rowSpan })}
                    >
                        {col.selector ? (
                            <div className='dtr-selector'>
                                {selectionMode === 'multiple' ? (
                                    <BaseDataTableCheckbox
                                        ref={selectionOn !== 'row' ? innerRef : undefined}
                                        disabled={disabled}
                                        checked={selected}
                                        {...(disabled || selectionOn === 'row'
                                            ? { tabIndex: -1, onChange: () => {} }
                                            : { onChange: handleSelect, onKeyUp: handleEnter })}
                                    />
                                ) : selectionMode === 'single' ? (
                                    <BaseDataTableRadio
                                        ref={selectionOn !== 'row' ? innerRef : undefined}
                                        disabled={disabled}
                                        checked={selected}
                                        {...(disabled || selectionOn === 'row'
                                            ? { tabIndex: -1, onChange: () => {} }
                                            : { onChange: handleSelect, onKeyUp: handleEnter })}
                                    />
                                ) : null}
                            </div>
                        ) : typeof col.cell === 'function' ? (
                            col.cell(value, { rowIndex, disabledRow: disabled })
                        ) : col.cell ? (
                            col.cell
                        ) : !['', null, undefined].includes(data) ? (
                            data
                        ) : typeof col.default === 'function' ? (
                            col.default(value, { rowIndex, disabledRow: disabled })
                        ) : (
                            (col.default ?? '')
                        )}
                    </BaseTd>
                )
            })}
        </BaseTr>
    )
}
