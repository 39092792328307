import PdfViewer from 'Component/PdfViewer'
import { ButtonIcon } from 'modules/modular/ButtonIcon'
import { Select } from 'modules/modular/Select'
import { useEffect, useState } from 'react'
import ContabilidadServices from 'services/contabilidad.service'

const claveManual = 'manual_fondo_rotatorio_y_fondo_avance'

export function Ayuda() {
    const [urlManual, setUrlManual] = useState('')
    const [percentage, setPercentage] = useState('75%')

    useEffect(() => {
        const load = async () => {
            let _urlManual = ''
            const { status, data } = await ContabilidadServices.getConfiguracion({ claves: claveManual })
            if (status === 200) {
                if (data.data[claveManual]) {
                    _urlManual = await getPdfObjectUrl(data.data[claveManual].valor)
                }
            }
            setUrlManual(_urlManual)
        }
        load()
    }, [])

    const getPdfObjectUrl = async (filename) => {
        // Paso 1: Descargar el PDF desde la URL
        const { status, data } = await ContabilidadServices.getManual(filename)
        if (status === 200) {
            // Paso 2: Crear un ObjectURL a partir del Blob
            const blobUrl = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
            return blobUrl
        }
        return ''
    }
    const openManual = () => {
        urlManual && window.open(urlManual, '_blank')
    }

    return (
        <div className='d-flex flex-column gap-4 align-items-center p-4'>
            <div className='container mt-0'>
                <div className='text-center fs-3 mb-3'>Manual de Usuario</div>
                <div
                    className='d-flex align-items-center p-1'
                    style={{ border: '2px solid #dee2e6', borderBottom: 'none', backgroundColor: '#f8f9fa' }}
                >
                    <div>
                        <Select
                            options={['50%', '75%', '100%', '125%', '150%', '200%', '250%']}
                            className='w-auto'
                            size='sm'
                            placeholder=''
                            value={percentage}
                            onChange={(e) => setPercentage(e.target.value)}
                            style={{backgroundColor: 'inherit'}}
                        />
                    </div>
                    <div className='ms-auto'>
                        <ButtonIcon variant='' className='fs-5 border-0' onClick={openManual} disabled={!urlManual}>
                            <span className='bi-box-arrow-in-up-right' style={{ color: '#6c757d' }} />
                        </ButtonIcon>
                    </div>
                </div>
                <div
                    className='w-100'
                    style={{ height: '100vh', border: '2px solid #dee2e6', backgroundColor: '#e9ecef' }}
                >
                    {urlManual ? (
                        <PdfViewer fileUrl={urlManual} percentage={percentage} />
                    ) : (
                        <div className='h-100 d-flex align-items-center justify-content-center fs-5'>No disponible</div>
                    )}
                </div>
            </div>
        </div>
    )
}
