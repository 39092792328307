import React from 'react'
import { useState, useRef, useEffect } from "react";
import Tablareceipts from "../Component/ReceiptTable"
import PopUp from "../Component/PopUp"
import PopUpConfirm from '../Component/PopUpConfirm';
import PopUpMessage from '../Component/PopUpMessage';
import SalesReport from '../Reports/SalesReport';
import UserServices from "../services/user.service"
import "../Styles/ReceiptSearch.css";
import generateMatricula from '../Reports/components/matricula/printTuition';
import services from '../services/report.service';


const ReceiptSearch = (props) => {

    const userServices = new UserServices();

    const [receipt, setReceipt] = useState("");
    const [motive, setMotive] = useState("");
    const [data, setData] = useState([]);
    const [personDocument, setDocument] = useState("");
    const [codSis, setCodSis] = useState("");
    const [selected, setSelected] = useState(null);
    const [receiptSelected, setReceiptSelected] = useState(null);
    const [isVisible, setIsVisible] = useState(false)
    const [isVisibleConfirm, setIsVisibleConfirm] = useState(false)
    const [popUpMessageVisible, setPopUpMessageVisible] = useState(false)
    const [numRecibo, setNumRecibo] = useState(null);
    const refReceipt = useRef(null);
    const refTable = useRef(null);
    const lastRequest = useRef(0);
    const currentRequest = useRef(0);
    const refCancel = useRef(null);
    const refMotive = useRef(null);
    const refMessage = useRef('');
    const [dataPrint, setDataPrint] = useState([]);
    const [admin, setAdmin] = useState(false);
    const [operation, setOperation] = useState(admin ? "imprimir" : "cancel");
    const [cancel, setCanceled] = useState(true);
    const [estructuras, setEstructuras] = useState([]);
    const [idEstructura, setIdEstructura] = useState(0);
    const [enterKeyStrokeCount, setEnterKeyStrokeCount] = useState(0);

    useEffect(() => {
        document.title = 'Caja UMSS - Anular o re-imprimir recibo'
        loadRoleUser();
        loadEstructuras();
    }, [])

    useEffect(() => {
        setCanceled(!admin)
    }, [admin])

    const loadEstructuras = async () => {
        await services.getListEstructure().then((data) => {
            setEstructuras(data)
        })
    }

    const loadRoleUser = async () => {
        await services.getUserRole().then(async (data) => {
            setAdmin(data.admin || data.adminReport);
        })
    }

    const select = (idselected, receipt) => {
        setSelected(idselected);
        setReceiptSelected(receipt);
    }

    const search = async (parameter, type, id, action) => {
        let data = null;
        data = await userServices.searchReceipt((parameter), type, action, idEstructura);
        if (data.result && data.isOK) {
            setDataPrint(data.result);
            let result = []
            data.result.forEach((object) => {
                let total = 0
                object.items.forEach(item => {
                    total = parseFloat(item.cost) + total
                })
                object = {
                    Fecha: object.fecha, Nro: object.recibo, Persona: object.persona_id ? object.persona_id : object.persona, Importe: total,
                    Usuario: object.usuario
                }
                result.push(object)
                total = 0
            })
            data = result

        }

        else data = [];
        if (id > currentRequest.current) {
            setData(data);
            currentRequest.current = id;
        }
    }

    const changeDocument = (e, searchDocument) => {
        setEnterKeyStrokeCount(0);
        lastRequest.current = lastRequest.current + 1
        if (e.length > 40) {
            e = e.slice(0, 40);
        }
        e = e.replaceAll(/[^0-9]/g, '')
        e = e.toUpperCase();
        setDocument(e);
        setCodSis("");
        setReceipt("");
        let action = cancel ? "anular" : "imprimir"
        if (e.length > 0 && searchDocument) {
            search(e, "document", lastRequest.current, action);
        } else {
            setDataPrint([]);
            setData([]);
        }
        setSelected(null);
        setReceiptSelected(null);
    }

    const changeSis = (e, searchSis) => {
        setEnterKeyStrokeCount(0);
        lastRequest.current = lastRequest.current + 1
        if (e.length > 40) {
            e = e.slice(0, 40);
        }
        e = e.replaceAll(/[^0-9]/g, '')
        e = e.toUpperCase();
        setCodSis(e);
        setDocument("");
        setReceipt("");
        let action = cancel ? "anular" : "imprimir"
        if (e.length > 0 && searchSis) {
            search(e, "sis", lastRequest.current, action);
        } else {
            setData([]);
            setDataPrint([]);
        }
        setSelected(null);
        setReceiptSelected(null);
    }

    const changeReceipt = (e, searchReceipt) => {
        setEnterKeyStrokeCount(0);
        if (!isNaN(e) && e.split('').length <= 8) {
            lastRequest.current = lastRequest.current + 1
            setReceipt(e);
            let action = cancel ? "anular" : "imprimir"
            if (e !== '' && searchReceipt) {
                search(e, "receipt", lastRequest.current, action);
            } else {
                setData([]);
                setDataPrint([]);
            }
            setSelected(null);
            setReceiptSelected(null);
            setReceipt(e);
            setDocument("");
            setCodSis("");
        }
    }

    const reprint = async () => {
        let received = dataPrint[selected]
        let nroRecibo = [received.recibo]
        const documento = received.complemento ? `${received.documento}-${received.complemento}` :received.documento
        if (received.movimiento !== null) {
            await generateMatricula(
                received.movimiento,
                received.persona,
                received.movimiento.distribucion_matricula,
                received.movimiento.payload,
                false,
                documento,
                received.usuario
            );
        } else {
            const report = new SalesReport()
            report.generateReport(
                nroRecibo,
                received.usuario,
                received.persona,
                received.items,
                received.observacion,
                documento,
                received.codigo,
                received.fecha,
                1
            );
        }
    }
    /**
     * Verifica si existe algún elemento de la lista esta seleccionado, en caso que
     * exista un elemento seleccionado verifica si la acción es anular, en caso de
     * que sea anular mostrara el popup de confirmación del recibo, y en caso que no
     * sea anular, es decir imprimir ejecuta la función receiptAction
     */
    const verifyAction = () => {
        if (selected !== null) {
            if (cancel) {
                setNumRecibo(dataPrint[selected].recibo)
                setIsVisibleConfirm(true);
            } else {
                receiptAction();
            }
        } else {
            setPopUpMessageVisible(true)
        }
    }
    const receiptAction = async () => {
        let res = null;
        let type = cancel ? "anular" : "imprimir";
        let idUser = dataPrint[selected].usuarioId ? dataPrint[selected].usuarioId : dataPrint[selected].user_id;
        let idMovimiento = dataPrint[selected].idMovimiento;

        res = await userServices.sendReceipt(receiptSelected.Nro, type, idUser, motive, idMovimiento);

        if (res.data) refMessage.current = res.data.result

        if (type === 'imprimir') {
            reprint()
            return
        }
        setIsVisible(true);

    }

    const clean = () => {
        refReceipt.current.focus();
        setData([]);
        setDocument("");
        setCodSis("");
        setReceipt("");
        setMotive("");
        setSelected(null);
        setReceiptSelected(null);
        refMessage.current = '';
    }

    const changeAction = (e) => {
        setOperation(e);
        clean();
        if (e === "cancel") {
            setCanceled(true);
        }
        else setCanceled(false);
    }

    const fastChange = (e) => {
        if (e.key === "Enter") {
            switch (operation) {
                case "cancel":
                    changeAction("imprimir");
                    break;
                case "imprimir":
                    changeAction("cancel");
                    break;
                default:
                    break;
            }
        }
    }

    const keyPress = (e) => {
        if (e.key === "Enter" && data.length >= 1) {
            refTable.current.focusFirst();
        }
    }

    const keyPressReceiptNumberInput = async (e) => {
        if(e.key === "Enter"){
            if(enterKeyStrokeCount === 4){
                setEnterKeyStrokeCount(0);
            }
            if(enterKeyStrokeCount === 0){
                let action = cancel ? "anular" : "imprimir"
                await search(receipt, "receipt", lastRequest.current, action);
            }
            if (enterKeyStrokeCount < 4){
                setEnterKeyStrokeCount(enterKeyStrokeCount+1);
            }
            if (data.length >= 1) {
                refTable.current.focusFirst();
            }
        }
    }

    const keyPressDocumentNumberInput = async (e) => {
        if(e.key === "Enter"){
            if(enterKeyStrokeCount === 4){
                setEnterKeyStrokeCount(0);
            }
            if(enterKeyStrokeCount === 0){
                let action = cancel ? "anular" : "imprimir"
                await search(personDocument, "document", lastRequest.current, action);
            }
            if (enterKeyStrokeCount < 4){
                setEnterKeyStrokeCount(enterKeyStrokeCount+1);
            }
            if (data.length >= 1) {
                refTable.current.focusFirst();
            }
        }
    }

    const keyPressSisNumberInput = async (e) => {
        if(e.key === "Enter"){
            if(enterKeyStrokeCount === 4){
                setEnterKeyStrokeCount(0);
            }
            if(enterKeyStrokeCount === 0){
                let action = cancel ? "anular" : "imprimir"
                await search(codSis, "sis", lastRequest.current, action);
            }
            if (enterKeyStrokeCount < 4){
                setEnterKeyStrokeCount(enterKeyStrokeCount+1);
            }
            if (data.length >= 1) {
                refTable.current.focusFirst();
            }
        }
    }

    const goToCancel = (e) => {
        if (e.key === "Enter") {
            refCancel.current.focus();
        }
    }

    const focusNext = () => {
        if (refCancel.current) refCancel.current.focus();
    }

    const focusStart = () => {
        if (refReceipt.current) refReceipt.current.focus();
    }

    const focusMotive = () => {
        if (refMotive.current) refMotive.current.focus();
    }

    const closePopUp = () => {
        setIsVisibleConfirm(false)
        focusStart()
        setNumRecibo(null)
    }

    const confirmAction = () => {
        closePopUp()
        receiptAction()
    }

    const setValidMotive = (value) => {
        if (value.length > 250) {
            value = value.slice(0, 250);
        }
        setMotive(value);
    }

    let col = ["Fecha", "Número Recibo", "Persona", "Importe Bs.", "Usuario"];
    const [columns,] = useState(col);

    return (
        <div >
            <div style={isVisible ? { pointerEvents: "none", marginLeft: 'auto', marginRight: "auto" } : { marginLeft: 'auto', marginRight: "auto" }} className="container shadow-sm p-3 bg-body rounded text-center">
                {admin ?
                    <div className='d-flex flex-column align-items-center '>
                        <h4>Seleccionar Estructura</h4>
                        <select className='w-50 form-select ' onChange={e => {
                            setIdEstructura(e.target.value);
                            clean();
                        }}>
                            <option value={0}>Todas al estructuras</option>
                            {
                                estructuras.map((estructura) => {
                                    return <option value={estructura.id} key={estructura.id} >{estructura.descripcion}</option>
                                })
                            }
                        </select>
                        <h6 className="text-muted mt-2 mb-0">Se recomienda siempre escoger un estructura</h6>
                    </div>
                    : null
                }
                <div className="secondary searchFields">
                    <div className="field">
                        <b>Acción </b>
                        <select className='btn btn-primary bt2' onKeyPressCapture={fastChange} value={operation} onChange={(e) => { changeAction(e.target.value) }} style={{ width: "100%" }}>
                            {!admin ?
                                <option value="cancel" >Anular</option>
                                : <></>
                            }
                            <option value="imprimir">Imprimir</option>
                        </select>
                    </div>
                    <div className="field">
                        <b>Número Recibo</b>
                        <input className='form-control form-control-sm' ref={refReceipt} autoFocus onChange={(e) => { changeReceipt(e.target.value, false) }} onKeyPressCapture={keyPressReceiptNumberInput} onBlur={(e) => { changeReceipt(e.target.value, true) }} value={receipt}/>
                    </div>
                    <div className="field">
                        <b>Documento</b>
                        <input className='form-control form-control-sm' onChange={(e) => { changeDocument(e.target.value, false) }} onKeyPressCapture={keyPressDocumentNumberInput} onBlur={(e) => { changeDocument(e.target.value, true) }} value={personDocument}></input>
                    </div>
                    <div className="field">
                        <b>Código SIS</b>
                        <input className='form-control form-control-sm' onChange={(e) => { changeSis(e.target.value, false) }} onKeyPressCapture={keyPressSisNumberInput} onBlur={(e) => { changeSis(e.target.value, true) }}value={codSis}></input>
                    </div>
                </div>
                <Tablareceipts
                    focusStart={focusStart}
                    focusMotive={focusMotive}
                    focusNext={focusNext}
                    ref={refTable}
                    cancel={cancel}
                    columns={columns}
                    data={data}
                    select={select}
                    selected={selected}
                    width="90%"
                    height="40vh"
                />
                <div className='secondary'>
                    <div className="motive-field" style={{ display: cancel ? "inherit" : "none" }}>
                        <b>Motivo</b>
                        <input className='form-control form-control-sm' onKeyPressCapture={goToCancel} ref={refMotive} value={motive} onChange={(e) => { setValidMotive(e.target.value) }} type="text" ></input>
                    </div>
                    <div className='botones-search-receipt justify-content-center'>
                        <button className='btn btn-primary bt2' ref={refCancel} onClick={verifyAction}>{cancel ? "Anular" : "Imprimir"}</button>
                    </div>
                    <div className='instructions'>
                        <p>Teclas rápidas: ENTER en los campos de búsqueda para pasar a la tabla donde se muestran los datos, S/W para navegar dentro los datos de la tabla, Q para volver al inicio (buscar por número de recibo), E para pasar al campo motivo, ENTER para pasar al botón de Anular/Imprimir</p>
                    </div>
                </div>
                <PopUp
                    message={refMessage.current}
                    visible={isVisible}
                    cancel={cancel}
                    close={setIsVisible}
                    focusStart={focusStart}
                    clean={clean}
                />
                <PopUpConfirm
                    visible={isVisibleConfirm}
                    close={closePopUp}
                    message={`¿Esta seguro de anular el recibo ${dataPrint[selected]?.recibo} de la persona ${dataPrint[selected]?.persona} y del usuario ${dataPrint[selected]?.usuario}?`}
                    confirmAction={confirmAction}
                />
                <PopUpMessage
                    close={() => { setPopUpMessageVisible(false); focusStart() }}
                    visible={popUpMessageVisible}
                    message='Seleccione un recibo de la tabla'
                />
            </div>
        </div>
    );
};
export default ReceiptSearch;