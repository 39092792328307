import { useEffect, useRef, useState } from "react";
import PopUp from "../../Component/PopUpMessage";
import CobrosElectronicos from "../../Component/facturas/CobrosElectronicos";
import DatosCuenta from "../../Component/facturas/DatosCuenta";
import DatosExtra from "../../Component/facturas/DatosExtra";
import DatosFactura from "../../Component/facturas/DatosFactura";
import FacturaServices from "../../services/factura.service";

const mensajeInicial = {visible:false, mensaje:""}
const datosInicial = {"nit":"","tipo_documento":1,"nombre":"", "correo":""}
const datosCuentaInicial = {"codigo":"","nombre":"","unidad":"", "precio":"","cantidad":1,"total":0,"tipo_monto":"F"}
const datosExtraInicial = {"observacion":"","descuento":"0","subtotal":""}

const errorsInitial = {cliente: {}, cuenta: {}, extra: {}, adicionales: {}}

function Facturas (){

    const [datos, setDatos] = useState(datosInicial)
    const [datosCuenta, setDatosCuenta] = useState(datosCuentaInicial)
    const [datosExtra, setDatosExtra] = useState(datosExtraInicial)
    const [cargando, setCargando] = useState(false)
    const [mensaje, setMensaje] = useState(mensajeInicial)
    const facturarRef = useRef(null)
    const [cuentasAdicionales, setCuentasAdicionales] = useState([])
    const [errors, setErrors] = useState(errorsInitial)
    const [touched, setTouched] = useState({})
    
    useEffect(()=>{
        const sumarCuentasAsociadas = cuentasAdicionales.reduce((carry,cuenta)=>{
            return carry+cuenta.precio*cuenta.cantidad
        },0)
        const _datosExtra = {...datosExtra, subtotal: parseFloat(datosCuenta.total)+parseFloat(sumarCuentasAsociadas)}
        setDatosExtra(_datosExtra)
        // validarDatos({extra: _datosExtra}, 'descuento')
    },[datosCuenta.total, cuentasAdicionales])
    
    const handleSubmit = async () => {
        setCargando(true)
        if (validarDatos({cliente: datos, cuenta: datosCuenta, extra: datosExtra, adicionales: cuentasAdicionales})) {
            const data = {
                datosFactura: datos,
                datosCuenta,
                datosExtra,
                cuentasAdicionales
            }
            
            const response= await FacturaServices.registrarMovimientoFacturas(data);
    
            if(response.data.isOk){
                const data = response.data.result;
                if(data !== null &&  ['validada', 'offline'].includes(String(data.mensaje).toLowerCase())){
                    const url= data.ver_factura;
                    window.open(url, '_blank');
                    limpiarFormulario();
                }else{
                    setMensaje({visible:true, mensaje:"No se pudo validar la factura"})
                }
            } else {
                setMensaje({ visible: true, mensaje: data.mensaje || 'Ocurrió un error al realizar la operación' })
            }
        }
        setCargando(false)
    }       

    const limpiarFormulario = () =>{
        setDatos(datosInicial)
        setDatosCuenta(datosCuentaInicial)
        setDatosExtra(datosExtraInicial)
        setCuentasAdicionales([])
        setErrors(errorsInitial)
        setTouched({})
    }

    /**
     * Valida los datos del formulario.
     * @param {object} values Datos a validar.
     * @param {string} name Name del input modificado.
     * @returns {boolean} true si los datos son validos.
     */
    const validarDatos = (values, name = '') => {
        const _errors = {cliente: null, cuenta: null, extra: null, adicionales: null}
        if (values.cliente) {
            _errors.cliente = validarDatosCliente(values.cliente, name)
            // Solo para validación en lote
            if (!name && values.cliente.correo) {
                if (!/^\w+([.-_]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(values.cliente.correo)) {
                    _errors.cliente.correo = 'Formato invalido'
                }
            }
        }
        if (values.cuenta) {
            const [errorsCuenta, _name] = validarDatosCuenta(values.cuenta, name)
            _errors.cuenta = errorsCuenta
            name = _name
        }
        if (values.extra) {
            _errors.extra = validarDatosExtra(values.extra, name)
        }
        if (values.adicionales) {
            const errorsAdicionales = {}
            values.adicionales.forEach((adicional, index) => {
                if (!(adicional.precio > 0) || !(adicional.cantidad > 0)) {
                    errorsAdicionales[`adicional-${index}`] = 'Total debe ser mayor a cero'
                }
            })
            _errors.adicionales = errorsAdicionales
        }
        _errors.cliente = _errors.cliente || errors.cliente
        _errors.cuenta = _errors.cuenta || errors.cuenta
        _errors.extra = _errors.extra || errors.extra
        _errors.adicionales = _errors.adicionales || errors.adicionales
        return !mostrarErrores(_errors, name)
    }
    const validarDatosCliente = (values) => {
        const _errors = {}
        if (!values.nit) {
            _errors.nit = 'Requerido'
        }
        if (!values.nombre) {
            _errors.nombre = 'Requerido'
        }
        if (!values.correo) {
            _errors.correo = 'Requerido'
        }
        return _errors
    }
    const validarDatosExtra = (values) => {
        const _errors = {}
        if (values.descuento >= values.subtotal) {
            _errors.descuento = 'Descuento debe ser menor que el subtotal'
        }
        return _errors
    }
    const validarDatosCuenta = (values, name) => {
        const _errors = {}
        if (!(values.precio > 0) || !(values.cantidad > 0)) {
            _errors.total = 'Total debe mayor a cero'
            if (name) name = 'total'
        }
        if (!values.id) {
            _errors.codigo = 'Requerido'
        }
        return [_errors, name]
    }
    /**
     * Actualiza los errores para mostrar.
     * @param {object} _errors Errores.
     * @param {string} name Name del input modificado.
     * @returns {boolean} true si hay errores.
     */
    const mostrarErrores = (_errors, name) => {
        const errorsKeys = [
            ...Object.keys(_errors.cliente), 
            ...Object.keys(_errors.cuenta), 
            ...Object.keys(_errors.extra),
            ...Object.keys(_errors.adicionales),
        ]
        setErrors(_errors)
        if (name) {
            setTouched({...touched, [name]: true})
        } else {
            setTouched({
                ...errorsKeys.reduce((carry, key) => {
                    carry[key] = true
                    return carry
                }, {})
            })
        }
        return errorsKeys.length > 0
    }
        
    return (
        <>
        <div className="w-xl-75 mx-auto">
            <div className='container shadow-sm bg-body rounded position-relative slight-responsive-increase' style={{ marginLeft: 'auto', marginRight: "auto", padding: 0 }}>
                <div style={{ padding: '1rem' }}>
           
                    <CobrosElectronicos/>
                    <DatosFactura 
                        datos={datos} 
                        setDatos={setDatos}
                        errors={errors.cliente}
                        touched={touched}
                        validar={validarDatos}
                    />
                    <DatosCuenta 
                        datos={datosCuenta} 
                        setDatos={setDatosCuenta} 
                        facturarRef={facturarRef}
                        errors={errors.cuenta}
                        touched={touched}
                        validar={validarDatos}
                    />
                    <DatosExtra 
                        datos={datosExtra} 
                        setDatos={setDatosExtra} 
                        cuentasAdicionales={cuentasAdicionales} 
                        setCuentasAdicionales={setCuentasAdicionales}
                        errors={{...errors.extra, ...errors.adicionales}}
                        touched={touched}
                        validar={validarDatos}
                    />

                    <div className='header text-center'>
                            <button type="button" className="btn btn-primary bt2"
                                disabled={cargando}
                                onClick={handleSubmit}
                                ref={facturarRef}
                                >
                                {cargando && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )} <span>Facturar</span>
                            </button>
                            <button type="button" className="btn btn-light-grey bt2" 
                                onClick={limpiarFormulario}
                                > 
                                <span>Limpiar</span>
                            
                            
                            </button> 
                    </div>
                </div>
            </div>
        </div>
        
        <PopUp
            close={()=>{setMensaje(mensajeInicial)}}
            visible={mensaje.visible}
            message={mensaje.mensaje}
        />
        </> 
    )


} 

export default Facturas;