import { fuenteFondoOptions, initialPagination } from 'data/constants'
import useFilters, { buildFilters, objectToQueryString } from 'hooks/useFilters'
import useHttpToast from 'hooks/useHttpToast'
import { Button } from 'modules/modular/Button'
import { Checkbox } from 'modules/modular/Checkbox'
import { DataTable } from 'modules/modular/DataTable'
import { FieldGroup } from 'modules/modular/FieldGroup'
import { Input } from 'modules/modular/Input'
import { Confirm } from 'modules/modular/Modal'
import { Select } from 'modules/modular/Select'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import ContabilidadServices from 'services/contabilidad.service'
import PresupuestosServices from 'services/presupuestos.service'
import { formatCurrency, formatDate } from 'utils'

const tiposFondo = {
    '': '',
    R: 'ROTATORIO',
    A: 'AVANCE',
}
const filterOptions = {
    equal: ['tipo', 'fuente_fondo', 'numero'],
    contain: ['beneficiario', 'actividad'],
    bool: ['activo'],
    betweenDate: ['fecha_creacion'],
}
const currentDate = new Date()
const currentYear = currentDate.getFullYear()
const formattedCurrentDate = formatDate(currentDate, 'Y-m-d')
const initialExtraFilters = { fecha_inicio: `${currentYear}-01-01`, fecha_fin: formattedCurrentDate }

const tipoFilterElement = ({ value, filterCallback }) => (
    <Select
        options={[
            { label: 'ROTATORIO', value: 'R' },
            { label: 'AVANCE', value: 'A' },
        ]}
        value={value}
        onChange={(e) => filterCallback(e.target.value)}
        style={{ minWidth: '8.5rem' }}
        placeholder='TODOS'
    />
)
const numeroFilterElement = ({ value, filterCallback }) => (
    <Input value={value} onChange={(e) => filterCallback(e.target.value)} type='number' />
)
const fuenteFondoFilterElement = ({ value, filterCallback }) => (
    <Select
        options={fuenteFondoOptions}
        value={value}
        onChange={(e) => filterCallback(e.target.value)}
        style={{ minWidth: '10rem' }}
        placeholder='TODOS'
    />
)
const checkboxFilterElement = ({ value, filterCallback }) => (
    <div className='d-flex justify-content-center'>
        <Checkbox
            value={value}
            onChange={(e) => filterCallback(e.target.value)}
            triState
            style={{ fontSize: '1.5rem' }}
        />
    </div>
)
const baseColumns = [
    {
        field: 'tipo',
        header: 'Tipo',
        cell: (rowData) => tiposFondo[rowData.tipo],
        cellClassName: 'text-center',
        filter: true,
        filterElement: tipoFilterElement,
        sortable: true,
    },
    {
        field: 'numero',
        header: 'Nro.',
        cell: (rowData) => rowData.numero ?? '-',
        cellClassName: 'text-center',
        filter: true,
        filterElement: numeroFilterElement,
        style: { width: '5rem' },
    },
    { field: 'beneficiario', header: 'Beneficiario', filter: true, sortable: true },
    { field: 'actividad', header: 'Unidad', filter: true },
    {
        field: 'monto_asignado',
        header: 'Monto Asignado',
        headerClassName: 'text-nowrap',
        cell: (rowData) => formatCurrency(rowData.monto_asignado),
        cellClassName: 'text-end',
    },
    {
        field: 'fuente_fondo',
        header: 'Fondos',
        cellClassName: 'text-center',
        filter: true,
        filterElement: fuenteFondoFilterElement,
        sortable: true,
    },
    {
        field: 'fecha_creacion',
        header: 'Fecha Registro',
        headerClassName: 'text-nowrap',
        cell: (rowData) => formatDate(rowData.fecha_creacion, 'd/m/Y'),
        cellClassName: 'text-center',
        sortable: true,
    },
    {
        field: 'activo',
        header: 'Activo',
        cell: (rowData) => (rowData.activo ? 'Si' : 'No'),
        cellClassName: 'text-center',
        filter: true,
        filterElement: checkboxFilterElement,
    },
]

export default function ListAllFondo({ tipo = 'CONTABILIDAD' }) {
    const { showHttpToast } = useHttpToast()
    const { user } = useSelector((state) => state.auth)
    const { filters, handlePage, handlePageSize, setFilters, reloadFilters, handleSort } = useFilters()
    const [extraFilters, setExtraFilters] = useState(initialExtraFilters)
    const [show, setShow] = useState({ increase: false })
    const [fondos, setFondos] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [selected, setSelected] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selection, setSelection] = useState([])

    useEffect(() => {
        const loadList = async () => {
            const _filters =
                tipo !== 'PRESUPUESTOS' && user?.da_id
                    ? { ...filters, filter: { ...filters.filter, da_id: user.da_id } }
                    : filters
            const params = objectToQueryString(_filters)
            const { status, data } = await ContabilidadServices.fondo.list(params)
            if (status === 200) {
                setFondos(data.data)
                setPagination(data.pagination)
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
        loadList()
    }, [filters, tipo])

    const openModal = (key) => setShow((prevShow) => ({ ...prevShow, [key]: true }))
    const closeModal = (key) => setShow((prevShow) => ({ ...prevShow, [key]: false }))

    const handleAction = (action, rowData) => {
        if (action === 'increase') {
            setSelected(rowData)
            openModal('increase')
        }
    }

    const optionBodyTemplate = (rowData) => {
        return (
            <Button
                startIcon={<span className='bi-plus-lg' />}
                size='sm'
                title='Incrementar Limite'
                disabled={!rowData.activo}
                onClick={() => handleAction('increase', rowData)}
            >
                Limite
            </Button>
        )
    }
    const columns = useMemo(() => {
        if (tipo === 'PRESUPUESTOS') {
            return [{ id: 'selector', selector: true }, ...baseColumns]
        } else {
            return [
                ...baseColumns,
                {
                    field: 'cantidad_finalizado',
                    header: 'Finalizado',
                    cell: (rowData) => `${rowData.cantidad_finalizado}/${rowData.limite_finalizado}`,
                    cellClassName: 'text-center',
                    filter: true,
                    filterElement: checkboxFilterElement,
                },
                {
                    field: 'id',
                    header: 'Opciones',
                    cell: optionBodyTemplate,
                    cellClassName: 'text-center',
                },
            ]
        }
    }, [tipo])

    const clearSelected = () => setSelected(null)

    const increaseLimitFondo = async () => {
        if (selected) {
            const { status, data } = await ContabilidadServices.fondo.increaseLimit(selected.id)
            showHttpToast(status, { detail: data.message })
            if (status === 200) reloadFilters()
            closeModal('increase')
        }
    }
    const generatePdf = () => {
        if (tipo === 'PRESUPUESTOS') {
            generatePrevisionPdf()
        } else {
            generateFondosPdf()
        }
    }
    const generateFondosPdf = async () => {
        setLoading(true)
        const _filters =
            tipo !== 'PRESUPUESTOS' && user?.da_id
                ? { ...filters, filter: { ...filters.filter, da_id: user.da_id } }
                : filters
        const fechaInicio = extraFilters.fecha_inicio || `${currentYear}-01-01`
        const fechaFin = extraFilters.fecha_fin || formattedCurrentDate
        const params = objectToQueryString({ ..._filters, fechas: { fecha_inicio: fechaInicio, fecha_fin: fechaFin } })
        const { status, data } = await ContabilidadServices.fondo.reportPdf(params)
        if (status === 200) {
            const pdfContent = data
            // Crear una URL local para el blob recibido
            const blobUrl = window.URL.createObjectURL(new Blob([pdfContent], { type: 'application/pdf' }))

            // Abrir una nueva pestaña con el PDF
            window.open(blobUrl, '_blank')
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al obtener el PDF' })
        }
        setLoading(false)
    }
    const generatePrevisionPdf = async () => {
        setLoading(true)
        const bodyParams = { fondo_id: selection.map((s) => s.id) }
        const { status, data } = await PresupuestosServices.fondo.previsionPdf(bodyParams)
        if (status === 200) {
            const pdfContent = data
            // Crear una URL local para el blob recibido
            const blobUrl = window.URL.createObjectURL(new Blob([pdfContent], { type: 'application/pdf' }))

            // Abrir una nueva pestaña con el PDF
            window.open(blobUrl, '_blank')
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al obtener el PDF' })
        }
        setLoading(false)
    }
    const generateXlsx = async () => {
        const id = selection.map((s) => s.id)
        const { status, data } = await PresupuestosServices.fondo.previsionXlsx({ fondo_id: id })

        if (status === 200) {
            // Crear un enlace temporal para realizar la descarga
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            const link = document.createElement('a')
    
            // Crear una URL para el blob
            link.href = URL.createObjectURL(blob)
    
            // Nombre del archivo de descarga
            link.download = 'reporte.xlsx'
    
            // Simular un clic en el enlace para descargar el archivo
            link.click()
    
            // Liberar el objeto URL
            URL.revokeObjectURL(link.href)
        } else {
            showHttpToast(status, { detail: data.message || 'Ocurrió un error al obtener el archivo' })
        }
    }

    const handleChangeExtraFilters = (e) => {
        const _extra = { ...extraFilters, [e.target.name]: e.target.value }
        setExtraFilters(_extra)
        const fechaInicio = _extra.fecha_inicio || `${currentYear}-01-01`
        const fechaFin = _extra.fecha_fin || formattedCurrentDate
        setFilters({
            ...filters,
            filter: {
                ...filters.filter,
                fecha_creacion: { '>': `${fechaInicio} 00:00:00`, '<': `${fechaFin} 23:59:59` },
            },
        })
    }
    const handleFilter = (filterValues) => {
        const fechaInicio = extraFilters.fecha_inicio || `${currentYear}-01-01`
        const fechaFin = extraFilters.fecha_fin || formattedCurrentDate
        const fecha_creacion = [fechaInicio, fechaFin]
        const _filters = { filter: buildFilters({ ...filterValues, fecha_creacion }, filterOptions) }
        if (typeof filterValues.cantidad_finalizado === 'boolean') {
            if (filterValues.cantidad_finalizado) {
                _filters.filter.cantidad_finalizado = { '>': 0 }
            } else {
                _filters.filter.cantidad_finalizado = 0
            }
        }
        if (filters) {
            if (filters.sort) _filters.sort = filters.sort
            if (filters.pageSize) _filters.pageSize = filters.pageSize
        }
        setFilters(_filters)
    }

    const handleSelectionChange = (s) => setSelection(s)

    const propsDataTable = (() => {
        if (tipo === 'PRESUPUESTOS') {
            return {
                selectionMode: 'multiple',
                selectionOn: 'row',
                selection,
                onSelectionChange: handleSelectionChange,
                // isRowSelectable: (rowData) => !rowData.activo,
            }
        }
        return {}
    })()

    return (
        <div className='h-100 p-4'>
            <div className='h-100 bg-white'>
                <div className='d-flex align-items-center bg-blue text-white py-3 px-4'>
                    <h2 className='mb-0 fw-normal'>Lista de Fondos</h2>
                </div>
                <div className='p-3'>
                    <div className='d-flex align-items-center gap-3 px-3 py-2 bg-body-tertiary'>
                        <FieldGroup label='Fecha: desde' inline className='mb-0'>
                            <Input
                                type='date'
                                name='fecha_inicio'
                                value={extraFilters.fecha_inicio}
                                onChange={handleChangeExtraFilters}
                            />
                        </FieldGroup>
                        -
                        <FieldGroup label='hasta' inline className='mb-0'>
                            <Input
                                type='date'
                                name='fecha_fin'
                                value={extraFilters.fecha_fin}
                                onChange={handleChangeExtraFilters}
                            />
                        </FieldGroup>
                        <Button
                            onClick={generatePdf}
                            loading={loading}
                            disabled={tipo === 'PRESUPUESTOS' && selection.length === 0}
                            className='ms-auto'
                        >
                            Generar PDF
                        </Button>
                        {tipo === 'PRESUPUESTOS' && (
                            <Button
                                variant='success'
                                onClick={generateXlsx}
                                loading={loading}
                                disabled={tipo === 'PRESUPUESTOS' && selection.length === 0}
                            >
                                Generar XLSX
                            </Button>
                        )}
                    </div>
                    <DataTable
                        columns={columns}
                        values={fondos}
                        lazy
                        pagination
                        currentPage={pagination.currentPage}
                        pageSize={pagination.pageSize}
                        totalItems={pagination.totalRecords}
                        onPageChange={handlePage}
                        onPageSizeChange={handlePageSize}
                        pageSizeOptions={[10, 25, 50]}
                        filterable
                        onFilter={handleFilter}
                        filterDelay={1000}
                        defaultAlignHeader='center'
                        onSort={handleSort}
                        rowKeyField='id'
                        {...propsDataTable}
                    />
                </div>
            </div>
            <Confirm
                show={show.increase}
                onHide={() => closeModal('increase')}
                onReject={() => closeModal('increase')}
                onAccept={increaseLimitFondo}
                onExited={clearSelected}
                closeOnBackdrop={false}
            >
                ¿Esta seguro de <span className='text-primary'>INCREMENTAR</span> el limite de finalizaciones del Fondo
                {selected?.tipo === 'R' ? ` Rotatorio N° ${selected.numero}` : ' en Avance'}?
            </Confirm>
        </div>
    )
}
