/**
 * Verifica si un valor está vacío.
 * Considera vacíos los valores: `null`, `undefined`, `false`, `NaN`, cadenas vacías, arreglos vacíos y objetos sin propiedades.
 * @param value El valor a verificar.
 * @returns Retorna true si el valor está vacío, de lo contrario retorna false.
 */
export function empty(value) {
    // Si el valor es falsy (null, undefined, false, 0, NaN, "")
    if (!value) {
        // Excluir cero como vacío
        return value !== 0
    }
    // Si es una cadena, verificar si está vacía o solo tiene espacios
    if (typeof value === 'string') {
        return value.replace(/\s+/g, '').length === 0
    }
    // Si es un arreglo, verificar si está vacío
    if (Array.isArray(value)) {
        return value.length === 0
    }
    // Si es un objeto, verificar si tiene propiedades propias enumerables
    if (typeof value === 'object') {
        return Object.keys(value).length === 0
    }
    // Para otros tipos de datos (booleanos, números, etc.), no se considera vacíos
    return false
}
