import LoadingSpinner from 'Component/LoadingSpinner'
import useHttpToast from 'hooks/useHttpToast'
import { formatCurrency, formatDate, getDecimalPart, numberName } from 'utils'
import { Button } from 'modules/modular/Button'
import { ButtonIcon } from 'modules/modular/ButtonIcon'
import { DataTable } from 'modules/modular/DataTable'
import { EditableSelect } from 'modules/modular/EditableSelect'
import { Input } from 'modules/modular/Input'
import { Select } from 'modules/modular/Select'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ContabilidadServices from 'services/contabilidad.service'
import { Confirm, Modal } from 'modules/modular/Modal'
import { FieldGroup } from 'modules/modular/FieldGroup'
import PresupuestosServices from 'services/presupuestos.service'
import { sliceObject } from 'modules/modular/utils'

const mapTiposDocumento = {}
const currentDate = formatDate(new Date(), 'Y-m-d')
const initialDocumento = {
    fecha: currentDate,
    numero_documento: '',
    tipo_documento: '',
}
const initialDetalle = {
    partida_presupuestaria: '',
    monto_total: '',
    rciva: '',
    it: '',
    iue: '',
    liquido_pagable: '',
    actividad: '',
}

export default function FormFondo({ tipo = 'view' }) {
    const { id } = useParams()
    const navigate = useNavigate()
    const { showHttpToast } = useHttpToast()
    const detalleRef = useRef(null)
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [show, setShow] = useState({ form: false, end: false, delete: false })
    const [info, setInfo] = useState(null)
    const [detalles, setDetalles] = useState([])
    const [selectedDetalle, setSelectedDetalle] = useState({
        index: null,
        value: initialDetalle,
        documento: initialDocumento,
    })
    const [actividades, setActividades] = useState([])
    const [errors, setErrors] = useState({})
    const [tiposDocumento, setTiposDocumento] = useState([])
    const [step, setStep] = useState(1)
    const [partidas, setPartidas] = useState([])

    const enableUpdate = tipo !== 'view' && info?.activo && info?.cantidad_finalizado < info?.limite_finalizado

    useEffect(() => {
        const load = async () => {
            const { status, data } = await ContabilidadServices.tipoDocumento.all()
            if (status === 200) setTiposDocumento(data.data)
        }
        load()
    }, [])
    useEffect(() => {
        const loadInforme = async () => {
            if (id) {
                const { status, data } = await ContabilidadServices.fondo.view(id)
                if (status === 200) {
                    const { detalles, ...infoFondo } = data.data
                    setInfo(infoFondo)
                    setDetalles(detalles.map((d) => ({ ...d, actividad: d.categoria_programatica })))
                    // Inicializar el detalle con información del fondo
                    setSelectedDetalle({
                        ...selectedDetalle,
                        value: { ...selectedDetalle.value, actividad: infoFondo.categoria_programatica },
                    })
                    if (infoFondo.categoria_programatica) {
                        searchActividades(infoFondo.categoria_programatica)
                    }
                    setLoading(false)
                } else {
                    showHttpToast(status, { detail: data.message })
                    navigate('/')
                }
            }
        }
        loadInforme()
    }, [id])

    const openModal = (key) => setShow({ ...show, [key]: true })
    const closeModal = (key) => setShow({ ...show, [key]: false })

    const handleAction = (action, detalle = null) => {
        setSelectedDetalle(detalle)
        if (['create', 'update'].includes(action)) {
            openModal('form')
        } else if (action === 'delete') {
            openModal('delete')
        }
    }

    const optionsTemplate = (rowData, { rowIndex }) => (
        <div>
            <ButtonIcon
                variant='danger'
                className='me-2'
                onClick={() => {
                    const { fecha, tipo_documento, numero_documento, ...value } = rowData
                    handleAction('delete', {
                        index: rowIndex,
                        value,
                        documento: { fecha, tipo_documento, numero_documento },
                    })
                }}
            >
                <span className='bi-trash lh-1' />
            </ButtonIcon>
            <ButtonIcon
                onClick={() => {
                    const {
                        fecha,
                        tipo_documento,
                        numero_documento,
                        detalle,
                        partida_presupuestaria_id,
                        partida,
                        ...value
                    } = rowData
                    handleAction('update', {
                        index: rowIndex,
                        value: {
                            ...value,
                            partida_presupuestaria: {
                                id: partida_presupuestaria_id,
                                nombre: detalle,
                                codigo: partida,
                            },
                        },
                        documento: { fecha, tipo_documento, numero_documento },
                    })
                    searchPartidas(detalle)
                }}
            >
                <span className='bi-pencil lh-1' />
            </ButtonIcon>
        </div>
    )
    const columns = useMemo(
        () => [
            {
                field: 'numero',
                header: 'N°',
                cell: (_, { rowIndex }) => rowIndex + 1,
                cellClassName: 'text-center',
            },
            {
                field: 'fecha',
                header: 'FECHA',
                cellClassName: 'text-center',
                cell: (rowData) => formatDate(rowData.fecha, 'd/m/Y'),
            },
            { field: 'detalle', header: 'DETALLE' },
            { field: 'numero_documento', header: 'N° DOCUMENTO', cellClassName: 'text-center' },
            { field: 'tipo_documento', header: 'TIPO DOCUMENTO' },
            {
                field: 'monto_total',
                header: 'MONTO TOTAL',
                cell: (rowData) => formatCurrency(rowData.monto_total),
                cellClassName: 'text-end',
            },
            {
                id: 'retenciones',
                header: 'RETENCIONES',
                columns: [
                    {
                        field: 'rciva',
                        header: '13% RC-IVA',
                        headerClassName: 'text-nowrap',
                        cell: (rowData) => formatCurrency(rowData.rciva),
                        cellClassName: 'text-end',
                    },
                    {
                        field: 'it',
                        header: '3% I.T.',
                        headerClassName: 'text-nowrap',
                        cell: (rowData) => formatCurrency(rowData.it),
                        cellClassName: 'text-end',
                    },
                    {
                        field: 'iue',
                        header: '5% I.U.E',
                        headerClassName: 'text-nowrap',
                        cell: (rowData) => formatCurrency(rowData.iue),
                        cellClassName: 'text-end',
                    },
                ],
            },
            {
                field: 'liquido_pagable',
                header: 'LIQUIDO PAGABLE',
                cell: (rowData) => formatCurrency(rowData.liquido_pagable),
                cellClassName: 'text-end',
            },
            {
                field: 'categoria_programatica',
                header: 'CATEGORIA PROGRAMATICA',
                cellClassName: 'text-center',
                style: { width: '10.5rem' },
            },
            {
                field: 'partida',
                header: 'PARTIDA PRESUPUESTARIA',
                cellClassName: 'text-center',
                style: { width: '10.5rem' },
            },
            ...(enableUpdate
                ? [
                      {
                          id: 'options',
                          header: 'OPCIONES',
                          cell: optionsTemplate,
                          cellClassName: 'text-center',
                      },
                  ]
                : []),
        ],
        [enableUpdate],
    )

    /** Función que valida los campos del formulario de adición/edición de detalle de reembolso. */
    const validateForm = (fields = 'all') => {
        const _errors = {}
        if (['all', 'documento'].includes(fields)) {
            if (!selectedDetalle.documento.fecha) {
                _errors.fecha = 'Requerido'
            }
            if (!selectedDetalle.documento.tipo_documento) {
                _errors.tipo_documento = 'Requerido'
            }
            if (!selectedDetalle.documento.numero_documento.toString().trim()) {
                _errors.numero_documento = 'Requerido'
            }
        }
        if (['all', 'detalle'].includes(fields)) {
            if (!selectedDetalle.value.partida_presupuestaria?.id) {
                _errors.partida_presupuestaria = 'Requerido'
            }
            if (!(selectedDetalle.value.monto_total > 0)) {
                _errors.monto_total = 'Requerido'
            }
            if (!selectedDetalle.value.actividad_id && !selectedDetalle.value.actividad?.id) {
                _errors.actividad = 'Requerido'
            }
        }
        return _errors
    }
    /** Handler que se encarga de controlar los campos del formulario de adición/edición de detalle de reembolso. */
    const handleChangeDetalle = (e) => {
        const { name, value } = e.target
        setSelectedDetalle({ ...selectedDetalle, value: { ...selectedDetalle.value, [name]: value } })
        handleErrors(name)
    }
    const handleChangeDocumento = (e) => {
        const { name, value } = e.target
        setSelectedDetalle({ ...selectedDetalle, documento: { ...selectedDetalle.documento, [name]: value } })
        handleErrors(name)
    }
    /** Maneja los errores al cambiar los valores de los campos del formulario de detalle. */
    const handleErrors = (fieldName) => {
        let _errors = null
        if (errors[fieldName]) {
            _errors = { ...errors, [fieldName]: false }
        }
        if (fieldName === 'monto_total' && errors.liquido_pagable) {
            _errors = { ..._errors, liquido_pagable: false }
        }
        if (_errors) {
            setErrors(_errors)
        }
    }
    /** Establece el detalle seleccionado a su valor inicial. */
    const resetSelectedDetalle = () => {
        setSelectedDetalle({
            index: null,
            value: { ...initialDetalle, actividad: info.categoria_programatica },
            documento: initialDocumento,
        })
        setErrors({})
        step !== 1 && setStep(1)
    }
    /** Registra/Edita un detalle del fondo */
    const handleCreateUpdate = async () => {
        const _errors = validateForm()
        if (Object.values(_errors).length > 0) {
            setErrors(_errors)
        } else {
            const { partida_presupuestaria, actividad, ...values } = selectedDetalle.value
            const value = {
                ...selectedDetalle.documento,
                ...values,
                detalle: partida_presupuestaria.nombre,
                partida: partida_presupuestaria.codigo,
                partida_presupuestaria_id: partida_presupuestaria.id,
                categoria_programatica: actividad?.codigo_actividad ?? selectedDetalle.value.actividad,
                actividad_id: actividad?.id ?? selectedDetalle.value.actividad_id,
                ...getRetenciones(),
                fondo_id: info.id,
            }
            const _detalle =
                selectedDetalle.index === null
                    ? [...detalles, value]
                    : [...detalles.map((d, i) => (i === selectedDetalle.index ? value : d))]
            // Verificar que el saldo sea mayor a cero
            const _totales = getTotales(_detalle)
            const saldo = info?.monto_asignado - _totales.total_liquido_pagable
            if (saldo > 0) {
                const { status, data } = value.id
                    ? await ContabilidadServices.fondoDetalle.update(value.id, value)
                    : await ContabilidadServices.fondoDetalle.create(value)
                showHttpToast(status, { detail: data.message })
                if (status === 200) {
                    // Edición
                    getDetalles()
                    closeModal('form')
                } else if (status === 201) {
                    // Creación
                    getDetalles()
                    setSelectedDetalle({
                        ...selectedDetalle,
                        value: {
                            ...initialDetalle,
                            actividad: info.categoria_programatica,
                        },
                    })
                    setErrors({})
                    detalleRef.current?.focus()
                }
            } else {
                setErrors({ liquido_pagable: 'El líquido pagable no debe exceder el saldo' })
            }
        }
    }
    /** Elimina un detalle del fondo. */
    const handleDelete = async () => {
        if (selectedDetalle.value.id) {
            const { status, data } = await ContabilidadServices.fondoDetalle.delete(selectedDetalle.value.id)
            showHttpToast(status, { detail: data.message })
            if (status === 200) {
                getDetalles()
                closeModal('delete')
            }
        }
    }
    /** Calcula las retenciones del detalle en registro/edición. */
    const getRetenciones = () => {
        let rciva = 0
        let it = 0
        let iue = 0
        const monto = selectedDetalle.value.monto_total
        const tipoDoc = selectedDetalle.documento.tipo_documento
        if (tipoDoc && monto > 0) {
            const tipo = mapTiposDocumento[tipoDoc] ?? tiposDocumento.filter((td) => td.nombre === tipoDoc)?.[0]
            if (tipo) {
                if (tipo.rciva) rciva = monto * 0.13
                if (tipo.it) it = monto * 0.03
                if (tipo.iue) iue = monto * 0.05
                mapTiposDocumento[tipoDoc] = tipo
            }
        }
        const lp = monto - rciva - it - iue
        return { rciva, it, iue, liquido_pagable: lp }
    }
    /**
     * Obtiene los totales de los detalles del reembolso.
     * @param {Array<object>} det Array de detalles del reembolso.
     */
    const getTotales = (det) => {
        return det.reduce(
            (carry, d) => {
                carry.total_monto_total += d.monto_total * 1
                carry.total_rciva += d.rciva * 1
                carry.total_it += d.it * 1
                carry.total_iue += d.iue * 1
                carry.total_liquido_pagable += d.liquido_pagable * 1
                return carry
            },
            {
                total_monto_total: 0,
                total_rciva: 0,
                total_it: 0,
                total_iue: 0,
                total_liquido_pagable: 0,
            },
        )
    }
    /**
     * Busca las actividades de la estructura programática que coinciden con el código pasado como parametro.
     * @param {string} codigo Código de la actividad con el siguiente formato:
     * - 01-02-000-000-10 -> DAF, 2 dígitos DA + 2 dígitos UE + 3 dígitos Programa + 3 dígitos Proyecto + 2 dígitos Actividad
     */
    const searchActividades = async (codigo) => {
        if (codigo.length > 0) {
            if (codigo.length < 17) {
                const { status, data } = await ContabilidadServices.searchActividades(codigo)
                if (status === 200) {
                    setActividades(data)
                }
            }
        } else {
            setActividades([])
        }
    }
    /** Llama al servicio para guardar el reembolso del fondo. */
    // const handleSave = async () => {
    //     setSubmitting(true)
    //     const { status, data } = await ContabilidadServices.fondo.update(info.id, { detalles })
    //     showHttpToast(status, { detail: data.message })
    //     setSubmitting(false)
    // }
    /** Llama al servicio para finalizar el reembolso del fondo. */
    const handleEnd = async () => {
        setSubmitting(true)
        closeModal('end')
        const { status, data } = await ContabilidadServices.fondo.end(info.id, {})
        showHttpToast(status, { detail: data.message })
        if (status === 200) {
            navigate(`/contabilidad/fondo/${info.id}`)
        }
        setSubmitting(false)
    }
    /** Obtiene el pdf del reembolso del fondo. */
    const generatePdf = async () => {
        setSubmitting(true)
        const { status, data } = await ContabilidadServices.fondo.pdf(info.id)
        if (status === 200) {
            const pdfContent = data
            // Crear una URL local para el blob recibido
            const blobUrl = window.URL.createObjectURL(new Blob([pdfContent], { type: 'application/pdf' }))

            // Abrir una nueva pestaña con el PDF
            window.open(blobUrl, '_blank')
        } else {
            showHttpToast(status, { detail: 'Ocurrió un error al obtener el PDF' })
        }
        setSubmitting(false)
    }
    /** Obtiene los detalles del fondo. */
    const getDetalles = async () => {
        if (info.id) {
            const { status, data } = await ContabilidadServices.fondo.details(info.id)
            if (status === 200) setDetalles(data.data.map((d) => ({ ...d, actividad: d.categoria_programatica })))
        }
    }

    const searchPartidas = async (partida) => {
        if (partida) {
            const { status, data } = await PresupuestosServices.partidaPresupuestaria.search({ partida, activos: 1 })
            if (status === 200) {
                setPartidas(data.data.map((partida) => sliceObject(partida, ['id', 'nombre', 'codigo'])))
            } else {
                showHttpToast(status, { detail: 'Ocurrió un error al buscar las partidas presupuestarias' })
            }
        } else {
            partidas.length && setPartidas([])
        }
    }

    const retenciones = getRetenciones()
    const totales = getTotales(detalles)

    const actividadOptionTemplate = (actividad) => {
        return (
            <span title={actividad.descripcion}>
                {`${actividad.codigo_actividad} / ${actividad.abreviacion_da} -  ${actividad.descripcion}`}
            </span>
        )
    }

    const footerElement = (
        <tfoot>
            <tr>
                <th colSpan={5} className='text-center'>
                    TOTALES
                </th>
                <th className='text-end'>{formatCurrency(totales.total_monto_total)}</th>
                <th className='text-end'>{formatCurrency(totales.total_rciva)}</th>
                <th className='text-end'>{formatCurrency(totales.total_it)}</th>
                <th className='text-end'>{formatCurrency(totales.total_iue)}</th>
                <th className='text-end'>{formatCurrency(totales.total_liquido_pagable)}</th>
                <th colSpan={2}></th>
            </tr>
            <tr>
                <th colSpan={9} className='text-end'>
                    {info?.tipo === 'R' ? 'SALDO EN CAJA' : 'DEPOSITO BANCARIO'}
                </th>
                <th className='text-end'>{formatCurrency(info?.monto_asignado - totales.total_liquido_pagable)}</th>
                <th colSpan={2}></th>
            </tr>
        </tfoot>
    )

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className='h-100 p-4'>
            <div className='bg-blue text-white text-center py-3 px-4'>
                <h2 className='mb-0 fw-normal'>
                    {info.tipo === 'R' ? `Fondo Rotatorio Nro ${info.numero ?? '#'}` : 'Cierre de Fondo en Avance'}
                </h2>
            </div>
            <div className='bg-white p-3'>
                <div className='d-flex mb-3'>
                    <div>
                        <span className='fw-bold me-2'>BENEFICIARIO:</span>
                        {info.beneficiario}
                        <br />
                        <span className='fw-bold me-2'>UNIDAD:</span>
                        {info.actividad}
                        <br />
                        <span className='fw-bold me-2'>MONTO ASIGNADO:</span>
                        {info ? formatCurrency(info.monto_asignado) : '0.00'}{' '}
                        {info &&
                            `- ${numberName(info.monto_asignado).toUpperCase()} ${getDecimalPart(
                                info.monto_asignado,
                            )}/100 BOLIVIANOS`}
                        <br />
                        <span className='fw-bold me-2'>FONDOS:</span>
                        {info.fuente_fondo}
                        <br />
                    </div>
                    <div className='ms-auto'>
                        {enableUpdate && (
                            <Button startIcon={<span className='bi-plus-lg' />} onClick={() => openModal('form')}>
                                Añadir
                            </Button>
                        )}
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    values={detalles}
                    className='table-bordered'
                    containerClassName='mb-3'
                    footerElement={footerElement}
                    rowKeyField='id'
                    defaultAlignHeader='center'
                />
            </div>
            <div className='bg-blue text-center p-3'>
                {(tipo === 'view' || (enableUpdate && info.cantidad_finalizado === 0)) && (
                    <Button
                        loading={submitting}
                        startIcon={<span className='bi-arrow-left' />}
                        className='me-3'
                        onClick={() => navigate(`/contabilidad/fondo-${info.tipo === 'A' ? 'avance' : 'rotatorio'}`)}
                    >
                        VOLVER
                    </Button>
                )}
                <Button className='me-3' onClick={generatePdf} loading={submitting}>
                    GENERAR PDF
                </Button>
                {enableUpdate && (
                    <>
                        <Button
                            variant='success'
                            className='me-3'
                            onClick={() => openModal('end')}
                            loading={submitting}
                        >
                            FINALIZAR
                        </Button>
                        {/* {info.cantidad_finalizado === 0 && (
                                <Button loading={submitting} onClick={handleSave}>
                                    GUARDAR
                                </Button>
                            )} */}
                    </>
                )}
            </div>
            <Confirm
                show={show.end}
                onHide={() => closeModal('end')}
                onReject={() => closeModal('end')}
                onAccept={handleEnd}
            >
                <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                    Una vez finalice el fondo no podrá realizar más modificaciones.
                    <br />
                    ¿Esta seguro de <span className='text-primary'>FINALIZAR</span> el
                    {info.tipo === 'R'
                        ? ` Reembolso del Fondo Rotatorio N° ${info.numero}`
                        : ' Cierre del Fondo en avance'}
                    ?
                </span>
            </Confirm>
            <Confirm
                show={show.delete}
                onHide={() => closeModal('delete')}
                onReject={() => closeModal('delete')}
                onAccept={handleDelete}
                onExited={resetSelectedDetalle}
            >
                <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                    ¿Esta seguro de <span className='text-primary'>ELIMINAR</span> el Detalle{' '}
                    {selectedDetalle.value.detalle} con Nro. de Documento {selectedDetalle.documento.numero_documento}?
                </span>
            </Confirm>
            <Modal
                show={show.form}
                onHide={() => closeModal('form')}
                onExited={resetSelectedDetalle}
                closeOnBackdrop={false}
                keyboard={false}
                style={{ width: '30rem' }}
            >
                <div className='px-3 py-2 border-bottom fs-4 fw-medium'>
                    {selectedDetalle.index === null ? 'Añadir' : ' Editar'} detalle
                </div>
                <div className='p-3'>
                    {step === 1 ? (
                        <div key='step-1'>
                            <p className='fw-medium fs-5'>Información del documento</p>
                            <FieldGroup label={<div style={{ width: '8rem' }}>Fecha:</div>} inline>
                                <Input
                                    type='date'
                                    name='fecha'
                                    value={selectedDetalle.documento.fecha}
                                    onChange={handleChangeDocumento}
                                    isInvalid={!!errors.fecha}
                                />
                            </FieldGroup>
                            <FieldGroup label={<div style={{ width: '8rem' }}>Tipo Documento:</div>} inline>
                                <Select
                                    options={tiposDocumento}
                                    optionLabel='nombre'
                                    optionValue='nombre'
                                    name='tipo_documento'
                                    value={selectedDetalle.documento.tipo_documento}
                                    onChange={handleChangeDocumento}
                                    isInvalid={!!errors.tipo_documento}
                                />
                            </FieldGroup>
                            <FieldGroup label={<div style={{ width: '8rem' }}>Nro. Documento:</div>} inline>
                                <Input
                                    name='numero_documento'
                                    value={selectedDetalle.documento.numero_documento}
                                    onChange={handleChangeDocumento}
                                    isInvalid={!!errors.numero_documento}
                                />
                            </FieldGroup>
                        </div>
                    ) : (
                        <div key='step-2'>
                            <p className='fw-medium fs-5'>Información del gasto</p>
                            <FieldGroup label={<div style={{ width: '7rem' }}>Detalle:</div>} inline>
                                <EditableSelect
                                    options={partidas}
                                    optionValue='nombre'
                                    valueAsOption
                                    lazy
                                    onSearch={searchPartidas}
                                    name='partida_presupuestaria'
                                    value={selectedDetalle.value.partida_presupuestaria}
                                    onChange={handleChangeDetalle}
                                    isInvalid={!!errors.partida_presupuestaria}
                                    containerClassName='w-100'
                                    menuStyle={{ minWidth: '100%' }}
                                />
                            </FieldGroup>
                            <FieldGroup label={<div style={{ width: '7rem' }}>Partida Pres.:</div>} inline>
                                <div className='form-control bg-body-secondary'>
                                    {selectedDetalle.value.partida_presupuestaria?.id ? (
                                        selectedDetalle.value.partida_presupuestaria.codigo
                                    ) : (
                                        <span style={{ color: 'transparent' }}>_</span>
                                    )}
                                </div>
                            </FieldGroup>
                            <FieldGroup label={<div style={{ width: '7rem' }}>Monto Total:</div>} inline>
                                <Input
                                    name='monto_total'
                                    type='currency'
                                    value={selectedDetalle.value.monto_total}
                                    onChange={handleChangeDetalle}
                                    isInvalid={!!errors.monto_total}
                                    className='text-end'
                                />
                            </FieldGroup>
                            <FieldGroup label={<div style={{ width: '7rem' }}>Retenciones.-</div>} inline>
                                <div>
                                    <FieldGroup
                                        label={
                                            <div style={{ width: '7rem' }} className='text-end'>
                                                RC-IVA (13%):
                                            </div>
                                        }
                                        className='mb-1'
                                        inline
                                    >
                                        <div className='form-control text-end bg-body-secondary'>
                                            {formatCurrency(retenciones.rciva)}
                                        </div>
                                    </FieldGroup>
                                    <FieldGroup
                                        label={
                                            <div style={{ width: '7rem' }} className='text-end'>
                                                I.T. (3%):
                                            </div>
                                        }
                                        className='mb-1'
                                        inline
                                    >
                                        <div className='form-control text-end bg-body-secondary'>
                                            {formatCurrency(retenciones.it)}
                                        </div>
                                    </FieldGroup>
                                    <FieldGroup
                                        label={
                                            <div style={{ width: '7rem' }} className='text-end'>
                                                I.U.E (5%):
                                            </div>
                                        }
                                        className='mb-0'
                                        inline
                                    >
                                        <div className='form-control text-end bg-body-secondary'>
                                            {formatCurrency(retenciones.iue)}
                                        </div>
                                    </FieldGroup>
                                </div>
                            </FieldGroup>
                            <FieldGroup
                                label={<div style={{ width: '7rem' }}>Liq. Pagable:</div>}
                                inline
                                showFeedback={!!errors.liquido_pagable}
                                feedback={errors.liquido_pagable}
                            >
                                <div
                                    className='form-control  bg-body-secondary text-end'
                                    style={
                                        errors.liquido_pagable
                                            ? { borderColor: 'var(--bs-form-invalid-border-color)' }
                                            : undefined
                                    }
                                >
                                    {formatCurrency(retenciones.liquido_pagable)}
                                </div>
                            </FieldGroup>
                            <FieldGroup
                                label={<div style={{ width: '7rem' }}>Cat. Prog.:</div>}
                                inline
                                className='mb-0'
                            >
                                <EditableSelect
                                    options={actividades}
                                    optionValue='codigo_actividad'
                                    optionTemplate={actividadOptionTemplate}
                                    valueAsOption
                                    lazy
                                    name='actividad'
                                    value={selectedDetalle.value.actividad}
                                    onChange={handleChangeDetalle}
                                    isInvalid={!!errors.actividad}
                                    placeholder='Buscar'
                                    containerClassName='w-100'
                                    menuStyle={{ minWidth: '100%' }}
                                />
                            </FieldGroup>
                        </div>
                    )}
                </div>
                <div className='px-3 py-2 border-top text-end'>
                    <Button
                        className='me-2'
                        variant='outline-primary'
                        onClick={() => closeModal('form')}
                        startIcon={<span className='bi-x-lg' />}
                    >
                        Cancelar
                    </Button>
                    {step === 1 ? (
                        <Button
                            onClick={() => {
                                const _errors = validateForm('documento')
                                if (Object.values(_errors).length > 0) {
                                    setErrors(_errors)
                                } else {
                                    setStep(2)
                                }
                            }}
                            startIcon={<span className='bi-chevron-right' />}
                        >
                            Siguiente
                        </Button>
                    ) : (
                        <>
                            <Button
                                className='me-2'
                                onClick={() => setStep(1)}
                                startIcon={<span className='bi-chevron-left' />}
                            >
                                Anterior
                            </Button>
                            <Button onClick={handleCreateUpdate} startIcon={<span className='bi-floppy' />}>
                                Guardar
                            </Button>
                        </>
                    )}
                </div>
            </Modal>
        </div>
    )
}
