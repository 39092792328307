import authHeader from './auth-header'
import { BaseRequest } from './request/baseRequest'
import { GenericRequest } from './request/request'

const PresupuestosServices = {
    claseGasto: {
        list: (params = {}) => GenericRequest.get('presupuestos/list-clase-gasto', params),
        create: (data) => GenericRequest.post('presupuestos/create-clase-gasto', data),
        update: (id, data) => GenericRequest.post('presupuestos/update-clase-gasto', data, { id }),
        delete: (id) => GenericRequest.post('presupuestos/delete-clase-gasto', {}, { id }),
    },
    partidaPresupuestaria: {
        list: (params = {}) => GenericRequest.get('presupuestos/list-partida-presupuestaria', params),
        create: (data) => GenericRequest.post('presupuestos/create-partida-presupuestaria', data),
        update: (id, data) => GenericRequest.post('presupuestos/update-partida-presupuestaria', data, { id }),
        delete: (id) => GenericRequest.post('presupuestos/delete-partida-presupuestaria', {}, { id }),
        search: (params) => GenericRequest.get('presupuestos/search-partida-presupuestaria', params),
    },
    fondo: {
        previsionPdf: (data = {}) =>
            BaseRequest.post('presupuestos/prevision-pdf', data, { responseType: 'blob', headers: authHeader() }),
        previsionXlsx: (data = {}) =>
            BaseRequest.post('presupuestos/prevision-xlsx', data, { responseType: 'blob', headers: authHeader() }),
    },
}

export default PresupuestosServices
