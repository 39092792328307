/**
 * Obtiene una porción de un objeto con las propiedades especificadas.
 * @param obj Objeto que contiene las propiedades.
 * @param props Propiedades a mantener en el objeto resultante.
 * @param defaultValues Valores predeterminados si una propiedad es indefinida.
 * @returns Retorna un objeto con propiedades especificadas.
 */
export function sliceObject(obj, props, defaultValues) {
    const _obj = {}
    props.forEach((key) => {
        _obj[key] = obj[key] === undefined ? (defaultValues ? defaultValues[key] : undefined) : obj[key]
    })
    const subObject = _obj
    return subObject
}
