import { useState } from 'react'
import FacturaServices from '../../services/factura.service'
import '../../Styles/ReceiptTable.css'
import PopUpMessage from '../../Component/PopUpMessage'
import PopUpConfirm from 'Component/PopUpConfirm'

function AnularFacturas() {
    const [factura, setFactura] = useState('')
    const [facturas, setFacturas] = useState([])
    const [visible, setVisible] = useState(false)
    const [mensaje, setMensaje] = useState('')
    const [seleccionado, setSeleccionado] = useState(-1)
    const [show, setShow] = useState({ confirmCancel: false })

    const toggleShow = (name) => setShow((prevShow) => ({ ...prevShow, [name]: !prevShow[name] }))

    const handleChange = (e) => {
        setFactura(e.target.value)
        facturas.length > 0 && setFacturas([])
        seleccionado >= 0 && setSeleccionado(-1)
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            buscarFactura()
        }
    }

    const buscarFactura = async () => {
        const response = await FacturaServices.buscarFacturas(factura)
        if (response.data.isOk) {
            setFacturas(response.data.result)
        } else {
            setMensaje(response.data.result)
            setVisible(true)
        }
    }

    const anular = async () => {
        // console.log(facturas[seleccionado])
        if (facturas[seleccionado]) {
            const response = await FacturaServices.anularFacturas(facturas[seleccionado].movimiento.id)
            if (response.data.success) {
                //ver qeu reopnse anular facturas del guido
                setSeleccionado(-1)
                setFacturas([])
                setFactura('')
            }
            setMensaje(response.data.message)
            setVisible(true)
        }
    }

    const reimprimir = async (id) => {
        // console.log(facturas[seleccionado]);
        // console.log(facturas[seleccionado].movimiento.id);

        if (facturas[seleccionado]) {
            const response = await FacturaServices.reimprimirFacturas(facturas[seleccionado].movimiento.id)
            // console.log(response.data);
            if (response.data.isOk) {
                const data = response.data.result.respuesta_facturacion
                if (data !== null && ['validada', 'offline'].includes(data.mensaje.toLowerCase())) {
                    const url = data.ver_factura
                    window.open(url, '_blank')
                } else {
                    setMensaje('Error')
                    setVisible(true)
                }
            }
        }
    }

    return (
        <div
            className='container shadow-sm p-3 bg-body rounded text-center mx-auto'
            style={{ flexdirection: 'column' }}
        >
            <div className='secondary searchFields d-flex justify-content-center'>
                <div>
                    <label className='form-label text-nowrap'>
                        <b>Número de Factura</b>
                    </label>
                    <input
                        type='text'
                        value={factura}
                        onChange={handleChange}
                        onKeyUp={handleKeyUp}
                        className='form-control form-control-sm w-auto'
                        required
                    />
                </div>
            </div>
            <div className='mb-4'>
                <table
                    className='table tablaRecibos'
                    style={{ position: 'relative', margin: 'auto', height: '30rem', width: '90%' }}
                >
                    <thead className='table-secondary'>
                        <tr>
                            <th>Fecha</th>
                            <th>Documento</th>
                            <th>Persona</th>
                            <th>Cuenta</th>
                        </tr>
                    </thead>
                    <tbody style={{ height: '30rem', backgroundColor: '#eeeeee' }}>
                        {facturas.map((fac, index) => {
                            return (
                                <tr
                                    key={index}
                                    onClick={() => setSeleccionado(index)}
                                    className={index === seleccionado ? 'selected' : undefined}
                                    style={index === seleccionado ? { transition: '0.75s' } : { transition: '0.1s' }}
                                >
                                    <td>{fac.fecha}</td>
                                    <td>{fac.nit}</td>
                                    <td>{fac.persona}</td>
                                    <td>{fac.cuenta}</td>
                                    {/* <td>{parseFloat(ex.precio).toFixed(2)}</td>                             */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <div className='header text-center'>
                <button
                    type='button'
                    className='btn btn-primary bt2'
                    // disabled={cargando}
                    onClick={() => toggleShow('confirmCancel')}
                    disabled={seleccionado < 0}
                >
                    <span>Anular</span>
                </button>
                <button
                    type='button'
                    className='btn btn-success bt2'
                    // disabled={cargando}
                    onClick={reimprimir}
                    disabled={seleccionado < 0}
                >
                    <span>Reimprimir</span>
                </button>
            </div>
            <PopUpMessage close={() => setVisible(false)} visible={visible} message={mensaje} />
            <PopUpConfirm
                visible={show.confirmCancel}
                message='Esta seguro de ANULAR la factura?'
                close={() => toggleShow('confirmCancel')}
                confirmAction={anular}
            />
        </div>
    )
}
export default AnularFacturas
