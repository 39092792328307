import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { classNames, joinStyles, propFromName } from '../utils'
import { Dropdown, useDropdownMenu, useDropdownToggle } from '@restart/ui'
import { BaseEditableSelectInput } from '../base/BaseEditableSelect'
import { createPortal } from 'react-dom'
export function EditableSelect({
    value,
    name = '',
    id = '',
    onChange,
    onFocus,
    onBlur,
    valueAsOption,
    options,
    optionValue = 'value',
    optionKey,
    optionTemplate,
    containerClassName,
    containerStyle,
    menuClassName,
    menuStyle,
    lazy,
    onSearch,
    portal,
    ...props
}) {
    const [show, setShow] = useState(false)
    const [hovered, setHovered] = useState(-1)
    const { menuOptions, selected } = useMemo(() => {
        const _keys = []
        const _selected = {}
        const _options = options.reduce((carry, option, index) => {
            // Obtener la key de la opción
            const _index = `option-${index}`
            const key = optionKey ? (getOptionProp(option, optionKey) ?? _index) : _index
            _keys.push(key)
            // Verificar si la opción esta seleccionada
            const _isSelected = isSelected(!!valueAsOption, option, value, optionValue)
            if (_isSelected) _selected[index] = option
            // Filtrar las opciones
            let insert = true
            if (lazy) {
                insert = true
            } else {
                insert = startsWith(getOptionProp(option, optionValue), getOptionProp(value, optionValue))
            }
            if (insert) {
                carry.push({
                    key: _keys.includes(key) ? _index : key,
                    index,
                    selected: _isSelected,
                    data: option,
                })
            }
            return carry
        }, [])
        return { menuOptions: _options, selected: Object.values(_selected)[0] ?? '' }
    }, [options, value, optionKey, optionValue, valueAsOption, lazy])
    const inputValue = useMemo(() => {
        return getOptionProp(value, optionValue)
    }, [value, optionValue])
    useEffect(() => {
        if (!show && hovered >= 0) setHovered(-1)
    }, [show])
    const buildEvent = (option) => {
        return {
            target: {
                name,
                id,
                value: valueAsOption ? option : getOptionProp(option, optionValue),
            },
        }
    }
    const handleSelect = (option) => {
        if (!option.selected) {
            onChange?.(buildEvent(option.data))
        }
        setShow(false)
    }
    const handleFocus = () => {
        onFocus?.(buildEvent(value))
    }
    const handleBlur = () => {
        onBlur?.(buildEvent(value))
    }
    const handleKeyDown = (e) => {
        if (show) {
            if (e.key.length > 0) {
                switch (e.key) {
                    case 'Enter':
                        if (hovered >= 0) {
                            handleSelect(menuOptions[hovered])
                        }
                        break
                    case 'ArrowUp':
                        const lastIndex = hovered - 1
                        if (lastIndex < 0) {
                            setShow(false)
                        }
                        setHovered(lastIndex)
                        break
                    case 'ArrowDown':
                        const nextIndex = hovered + 1
                        if (nextIndex < menuOptions.length) {
                            setHovered(nextIndex)
                        }
                        break
                    default:
                        break
                }
            }
        } else {
            if (['Delete', 'Backspace'].includes(e.key) || e.key.length === 1) {
                setShow(true)
            }
        }
    }
    const handleChangeInput = (e) => {
        const _value = e.target.value
        const searched = _value
            ? options.filter((option) => startsWith(getOptionProp(option, optionValue), _value))
            : []
        if (searched.length === 1 && compareStrings(getOptionProp(searched[0], optionValue), _value)) {
            onChange?.(buildEvent(searched[0]))
        } else {
            onChange?.(buildEvent(_value))
            lazy && onSearch?.(_value)
        }
    }
    return (
        <div className={classNames('modular-editable-select', containerClassName)} style={containerStyle}>
            <Dropdown show={show} onToggle={(nextShow) => setShow(nextShow)}>
                <EditableSelectField
                    {...props}
                    value={inputValue}
                    onChange={handleChangeInput}
                    onFocus={onFocus ? handleFocus : undefined}
                    onBlur={onBlur ? handleBlur : undefined}
                    onKeyDown={handleKeyDown}
                />
                {show && (
                    <EditableSelectMenu
                        options={menuOptions}
                        optionLabel={optionValue}
                        optionTemplate={optionTemplate}
                        onSelect={handleSelect}
                        className={menuClassName}
                        style={menuStyle}
                        hovered={hovered}
                        portal={portal}
                    />
                )}
            </Dropdown>
        </div>
    )
}
const EditableSelectField = (props) => {
    const [toggleProps, { show, toggle }] = useDropdownToggle()
    const handleClick = useCallback(
        (e) => {
            toggleProps.onClick(e)
        },
        [toggleProps.onClick],
    )
    return (
        <BaseEditableSelectInput
            {...props}
            ref={(el) => toggleProps.ref(el)}
            onClick={handleClick}
            className={classNames('mod-es-select', props.className)}
            aria-expanded={toggleProps['aria-expanded']}
        />
    )
}
function EditableSelectMenu({ options, optionLabel, optionTemplate, onSelect, hovered, portal, ...props }) {
    const optionsRef = useRef([])
    const [menuProps, { show, popper }] = useDropdownMenu({
        flip: true,
        offset: [0, 4],
        placement: 'bottom-start',
    })
    useLayoutEffect(() => {
        if (show) popper?.update()
    }, [show])
    useEffect(() => {
        if (hovered >= 0 && hovered < options.length) {
            optionsRef.current[hovered].scrollIntoView({ block: 'nearest' })
        }
    }, [hovered])
    const menuElement = (
        <div
            {...menuProps}
            {...props}
            className={classNames('mod-es-menu', props.className)}
            style={joinStyles(menuProps.style, props.style)}
            tabIndex={-1}
        >
            {options.length > 0 ? (
                options.map((option, index) => (
                    <div
                        key={option.key}
                        ref={(el) => {
                            if (el) optionsRef.current[index] = el
                        }}
                        className={classNames('mod-esm-option', {
                            selected: option.selected,
                            hovered: hovered === index,
                        })}
                        onClick={onSelect ? () => onSelect(option) : undefined}
                    >
                        <div className='mod-esmo-label'>
                            {optionTemplate?.(option.data) ?? getOptionProp(option.data, optionLabel)}
                        </div>
                    </div>
                ))
            ) : (
                <div className='mod-esm-empty-option'>Sin opciones</div>
            )}
        </div>
    )
    return portal ? createPortal(menuElement, document.body) : menuElement
}
const getOptionProp = (option, optionProp) => {
    if (typeof option === 'object' && option !== null) {
        const _prop = propFromName(option, optionProp)
        return toString(_prop)
    } else {
        return String(option ?? '')
    }
}
const toString = (data) => {
    if (data === null || data === undefined) {
        return ''
    }
    if (typeof data === 'object') {
        let str = data.toString()
        if (str === '[object Object]') {
            str = JSON.stringify(data)
        }
        return str
    }
    return String(data)
}
const isSelected = (valueAsOption, option, value, optionValue) => {
    const selected = Array.isArray(value) ? value : value ? [value] : []
    if (valueAsOption) {
        return selected.some((s) => toString(s) === toString(option))
    } else {
        return selected.some((s) => toString(s) === getOptionProp(option, optionValue))
    }
}
function startsWith(str, prefix) {
    return toLowerCase(str).startsWith(toLowerCase(prefix))
}
function compareStrings(str1, str2) {
    return toLowerCase(str1) === toLowerCase(str2)
}
function toLowerCase(str) {
    return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
}
