import { initialPagination } from 'data/constants'
import useFilters from 'hooks/useFilters'
import useHttpToast from 'hooks/useHttpToast'
import { AdvancedSelect } from 'modules/modular/AdvancedSelect'
import { Button } from 'modules/modular/Button'
import { ButtonIcon } from 'modules/modular/ButtonIcon'
import { Checkbox } from 'modules/modular/Checkbox'
import { DataTable } from 'modules/modular/DataTable'
import { FieldGroup } from 'modules/modular/FieldGroup'
import { Input } from 'modules/modular/Input'
import { Confirm, Modal } from 'modules/modular/Modal'
import { classNames } from 'modules/modular/utils'
import { useEffect, useMemo, useState } from 'react'
import PresupuestosServices from 'services/presupuestos.service'
import { formatDate } from 'utils'

const currentDate = formatDate(new Date())

const initialValues = {
    nombre: '',
    codigo: '',
    fecha_alta: currentDate,
    fecha_baja: '',
    clase_gasto_id: '',
    activo: true,
}

export default function PartidasPresupuestarias() {
    const { showHttpToast } = useHttpToast()
    const { filters, handlePage, handlePageSize, handleSort, reloadFilters } = useFilters()
    const [partidas, setPartidas] = useState([])
    const [pagination, setPagination] = useState(initialPagination)
    const [show, setShow] = useState({ form: false, confirmDelete: false })
    const [values, setValues] = useState(initialValues)
    const [errors, setErrors] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const [clasesGasto, setClasesGasto] = useState([])

    useEffect(() => {
        loadClasesGasto()
    }, [])
    useEffect(() => {
        const loadList = async () => {
            const { status, data } = await PresupuestosServices.partidaPresupuestaria.list(filters)
            if (status === 200) {
                setPartidas(data.data)
                setPagination(data.pagination)
            } else {
                showHttpToast(status, { detail: data.message })
            }
        }
        loadList()
    }, [filters])

    const toggleShow = (name) => setShow({ ...show, [name]: !show[name] })

    const handleAction = (action, rowData = null) => {
        if (action === 'toggleActive') {
            handleToggleActive(rowData)
        } else {
            rowData && setValues(rowData)
            if (action === 'update') {
                toggleShow('form')
            } else if (action === 'delete') {
                toggleShow('confirmDelete')
            }
        }
    }

    const optionsTemplate = (rowData) => (
        <div className='text-center'>
            <ButtonIcon onClick={() => handleAction('update', rowData)} title='Editar' style={{ fontSize: '1.2rem' }}>
                <span className='bi-pencil lh-1' style={{ fontSize: '1rem' }} />
            </ButtonIcon>
            <ButtonIcon
                variant={rowData.activo ? 'danger' : 'success'}
                className='ms-2'
                onClick={() => handleAction('toggleActive', rowData)}
                title={rowData.activo ? 'Desactivar' : 'Activar'}
                style={{ fontSize: '1.2rem' }}
            >
                <span
                    className={classNames(['lh-1', rowData.activo ? 'bi-lock' : 'bi-unlock'])}
                    style={{ fontSize: '1rem' }}
                />
            </ButtonIcon>
        </div>
    )
    const columns = useMemo(
        () => [
            { field: 'codigo', header: 'Partida', sortable: true },
            { field: 'nombre', header: 'Detalle', sortable: true },
            { field: 'clase_gasto', header: 'Clase de gasto', sortable: true },
            { field: 'fecha_alta', header: 'Fecha alta', className: 'text-center' },
            { field: 'fecha_baja', header: 'Fecha baja', default: 'INDEFINIDO', className: 'text-center' },
            {
                field: 'activo',
                header: 'Activo',
                cell: (rowData) => (rowData.activo ? 'Si' : 'No'),
                className: 'text-center',
            },
            {
                id: 'options',
                header: 'Opciones',
                headerClassName: 'text-center',
                headerStyle: { width: '10rem' },
                cell: optionsTemplate,
            },
        ],
        [],
    )

    const loadClasesGasto = async () => {
        const { status, data } = await PresupuestosServices.claseGasto.list({ all: true, sort: 'codigo' })
        if (status === 200) {
            setClasesGasto(data.data)
        } else {
            showHttpToast(status, { detail: 'No se pudo cargar las clases de gasto' })
        }
    }

    const validateValues = (values) => {
        const _errors = {}
        if (!values.codigo) _errors.codigo = 'Requerido'
        if (!values.nombre) _errors.nombre = 'Requerido'
        if (!values.clase_gasto_id) _errors.clase_gasto_id = 'Requerido'
        if (!values.fecha_alta) _errors.fecha_alta = 'Requerido'
        return {
            hasErrors: Object.values(_errors).length > 0,
            errors: _errors,
        }
    }
    const handleChangeValues = (e) => {
        const name = e.target.name
        setValues({ ...values, [name]: e.target.value })
        errors[name] && setErrors({ ...errors, [name]: '' })
    }
    const handleSubmit = async () => {
        setSubmitting(true)
        const { hasErrors, errors } = validateValues(values)
        if (hasErrors) {
            setErrors(errors)
        } else {
            if (values.id) {
                await handleUpdate()
            } else {
                await handleCreate()
            }
        }
        setSubmitting(false)
    }
    const handleCreate = async () => {
        const { status, data } = await PresupuestosServices.partidaPresupuestaria.create(values)
        showHttpToast(status, { detail: data.message })
        if (status === 201) {
            toggleShow('form')
            reloadFilters()
        }
    }
    const handleUpdate = async () => {
        if (values.id) {
            const { status, data } = await PresupuestosServices.partidaPresupuestaria.update(values.id, values)
            showHttpToast(status, { detail: data.message })
            if (status === 200) {
                toggleShow('form')
                reloadFilters()
            }
        }
    }
    const handleDelete = async () => {
        if (values.id) {
            const { status, data } = await PresupuestosServices.partidaPresupuestaria.delete(values.id)
            showHttpToast(status, { detail: data.message })
            toggleShow('confirmDelete')
            if (status === 200) {
                reloadFilters()
            }
        }
    }
    const handleToggleActive = async (value) => {
        if (value.id) {
            const bodyParams = { activo: !value.activo }
            const { status, data } = await PresupuestosServices.partidaPresupuestaria.update(value.id, bodyParams)
            showHttpToast(status, { detail: data.message })
            if (status === 200) reloadFilters()
        }
    }
    const clearStates = () => {
        setValues(initialValues)
        setErrors({})
    }

    return (
        <div className='h-100 p-4'>
            <div className='h-100 bg-white'>
                <div className='d-flex align-items-center bg-blue text-white py-3 px-4'>
                    <h2 className='mb-0 fw-normal'>Partidas Presupuestarias</h2>
                    <div className='ms-auto'>
                        <Button
                            startIcon={<span className='bi-plus-lg' />}
                            variant='light'
                            onClick={() => toggleShow('form')}
                        >
                            Nuevo
                        </Button>
                    </div>
                </div>
                <div className='p-3'>
                    <DataTable
                        values={partidas}
                        columns={columns}
                        lazy
                        pagination
                        totalItems={pagination.totalRecords}
                        currentPage={pagination.currentPage}
                        pageSize={pagination.pageSize}
                        pageSizeOptions={[10, 25, 50, 100]}
                        onPageChange={handlePage}
                        onPageSizeChange={handlePageSize}
                        onSort={handleSort}
                    />
                </div>
            </div>
            <Confirm
                show={show.confirmDelete}
                onHide={() => toggleShow('confirmDelete')}
                onReject={() => toggleShow('confirmDelete')}
                onAccept={handleDelete}
                onExited={clearStates}
            >
                <span className='lh-1' style={{ fontSize: '1.1rem' }}>
                    ¿Esta seguro de <span className='text-primary'>ELIMINAR</span> la Partida presupuestaria "
                    {values.nombre}"?
                </span>
            </Confirm>
            <Modal show={show.form} onExited={clearStates}>
                <div className='px-3 py-2 border-bottom fs-4 fw-medium'>
                    {values.id ? 'Editar' : 'Registrar'} Partida Presupuestaria
                </div>
                <div className='p-3'>
                    <FieldGroup label='Partida' feedback={errors.codigo} showFeedback={!!errors.codigo}>
                        <Input
                            type='number'
                            name='codigo'
                            value={values.codigo}
                            onChange={handleChangeValues}
                            isInvalid={!!errors.codigo}
                        />
                    </FieldGroup>
                    <FieldGroup label='Detalle' feedback={errors.nombre} showFeedback={!!errors.nombre}>
                        <Input
                            name='nombre'
                            value={values.nombre}
                            onChange={handleChangeValues}
                            isInvalid={!!errors.nombre}
                        />
                    </FieldGroup>
                    <FieldGroup
                        label='Clase de gasto'
                        feedback={errors.clase_gasto_id}
                        showFeedback={!!errors.clase_gasto_id}
                    >
                        <AdvancedSelect
                            options={clasesGasto}
                            optionLabel='nombre'
                            optionValue='id'
                            name='clase_gasto_id'
                            value={values.clase_gasto_id}
                            onChange={handleChangeValues}
                            containerClassName='w-100'
                            menuStyle={{ minWidth: '100%' }}
                            optionTemplate={(option) => `${option.codigo} - ${option.nombre}`}
                        />
                    </FieldGroup>
                    <FieldGroup label='Fecha de alta' feedback={errors.fecha_alta} showFeedback={!!errors.fecha_alta}>
                        <Input
                            type='date'
                            name='fecha_alta'
                            value={values.fecha_alta}
                            onChange={handleChangeValues}
                            isInvalid={!!errors.fecha_alta}
                        />
                    </FieldGroup>
                    <FieldGroup label='Fecha de baja' feedback={errors.fecha_baja} showFeedback={!!errors.fecha_baja}>
                        <Input
                            type='date'
                            name='fecha_baja'
                            value={values.fecha_baja}
                            onChange={handleChangeValues}
                            isInvalid={!!errors.fecha_baja}
                        />
                    </FieldGroup>
                    <label className='d-inline-flex align-items-center gap-2'>
                        Activo:
                        <Checkbox
                            name='activo'
                            value={values.activo}
                            onChange={handleChangeValues}
                            style={{ fontSize: '1.375rem' }}
                        />
                    </label>
                </div>
                <div className='px-3 py-2 border-top text-end'>
                    <Button
                        className='me-2'
                        variant='outline-primary'
                        onClick={() => toggleShow('form')}
                        startIcon={<span className='bi-x-lg' />}
                        disabled={submitting}
                    >
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} startIcon={<span className='bi-floppy' />} submitting={submitting}>
                        Guardar
                    </Button>
                </div>
            </Modal>
        </div>
    )
}
