import { BaseTd, BaseTfoot, BaseTr } from '../base/BaseDataTable'
import { classNames, joinStyles } from '../utils'
export default function DataTableFooter({ footerGroups, values }) {
    return (
        <BaseTfoot>
            {footerGroups.map((footerGroup, index) => {
                let restColSpan = 0
                return (
                    <BaseTr key={footerGroup.id}>
                        {footerGroup.headers.map((footer) => {
                            if (restColSpan) {
                                restColSpan--
                                return null
                            }
                            const col = footer.column
                            const arrayColSpan = Array.isArray(col.footerColSpan)
                                ? col.footerColSpan
                                : col.footerColSpan
                                  ? [col.footerColSpan]
                                  : []
                            const footerColSpan = arrayColSpan[footerGroups.length - 1 - index]
                            if (footerColSpan) restColSpan = footerColSpan - 1
                            const colSpan = footerColSpan ?? footer.colSpan
                            const cellProps = {
                                ...(colSpan > 1 && { colSpan }),
                                className: classNames(col.className, col.footerClassName),
                                style: joinStyles(col.style, col.footerStyle),
                            }
                            if (footer.isPlaceholder) {
                                return <BaseTd key={footer.id} {...cellProps}></BaseTd>
                            }
                            return (
                                <BaseTd key={footer.id} {...cellProps}>
                                    {typeof col.footer === 'function' ? col.footer({ values }) : col.footer}
                                </BaseTd>
                            )
                        })}
                    </BaseTr>
                )
            })}
        </BaseTfoot>
    )
}
