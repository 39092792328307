import React, { useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import '../Styles/PopUp.css'

function PopUpConfirm({ visible, close, message, confirmAction }) {
    const refButton = useRef(null)

    useEffect(() => {
        if (visible === true) if (refButton.current) refButton.current.focus()
        return () => {}
    }, [visible])

    return (
        <Modal show={visible} onHide={close} centered>
            <div className='PopUp' style={{ visibility: visible ? 'visible' : 'hidden' }}>
                <h3>{message}</h3>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                        className={'btn btn-primary bt2'}
                        style={{ marginRight: '16px', minWidth: '90px' }}
                        onClick={confirmAction}
                        ref={refButton}
                    >
                        Si
                    </button>
                    <button className={'btn btn-danger bt2'} style={{ minWidth: '90px' }} onClick={close}>
                        Cancelar
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default PopUpConfirm
