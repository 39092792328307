import { useState } from 'react'
import { formatDate } from 'utils'

/**
 * Manejo de filtros de Yii para el componente DataTable,
 * estructura filtros: {filter: {}, sort: string, page: number, pageSize: number}
 * @param {object} config - Configuración para filtros.
 * @param {object} config.initialFilters - Filtros iniciales.
 * @param {FilterOptions} config.filterOptions - Opciones de filtros.
 */
export default function useFilters({ initialFilters = {}, filterOptions = {} } = {}) {
    const [filters, setFilters] = useState(initialFilters)

    const handleFilter = (filterValues) => {
        setFilters((prevFilters) => {
            let _filters = {}
            if (Object.values(filterOptions).length > 0) {
                _filters = { filter: buildFilters(filterValues, filterOptions) }
            }
            if (prevFilters?.sort) _filters.sort = prevFilters.sort
            if (prevFilters?.pageSize) _filters.pageSize = prevFilters.pageSize
            return _filters
        })
    }
    const handleSort = (field, order) => {
        setFilters((prevFilters) => {
            const _filters = { ...prevFilters }
            delete _filters.sort
            const _field = field || ''
            const _sort = order === 'desc' ? { sort: `-${_field}` } : order === 'asc' ? { sort: _field } : {}
            return { ..._filters, ..._sort }
        })
    }
    const handlePage = (page) => setFilters((prevFilters) => ({ ...(prevFilters || {}), page }))
    const handlePageSize = (pageSize) => setFilters((prevFilters) => ({ ...(prevFilters || {}), pageSize }))
    const rebootFilters = () => setFilters({ ...initialFilters })
    const clearFilters = () => setFilters({})
    const reloadFilters = () => setFilters((prevFilters) => ({ ...(prevFilters || {}) }))

    return {
        filters,
        setFilters,
        handleFilter,
        handleSort,
        handlePage,
        handlePageSize,
        rebootFilters,
        clearFilters,
        reloadFilters,
    }
}

/**
 * @typedef {Object} FilterOptions
 * @property {boolean} filterOptions.allowEmpty - Especifica si tomara en cuenta strings vacíos o no. Default true.
 * @property {string[]} filterOptions.equal - Array de propiedades a filtrar con el operador "="
 * @property {string[]} filterOptions.contain - Array de propiedades a filtrar con el operador "ILIKE"
 * @property {string[]} filterOptions.gt - Array de propiedades a filtrar con el operador ">"
 * @property {string[]} filterOptions.lt - Array de propiedades a filtrar con el operador "<"
 * @property {string[]} filterOptions.in - Array de propiedades a filtrar con el operador "IN"
 * @property {string[]} filterOptions.nin - Array de propiedades a filtrar con el operador "NOT IN"
 * @property {string[]} filterOptions.is - Array de propiedades a filtrar con el operador "IS"
 * @property {string[]} filterOptions.isnt - Array de propiedades a filtrar con el operador "IS NOT"
 * @property {string[]} filterOptions.between - Array de propiedades a filtrar con el operador ">" y "<"
 * @property {string[]} filterOptions.betweenDate - Array de propiedades a filtrar con el operador ">" y "<" para fechas
 * @property {string[]} filterOptions.null - Array de propiedades a filtrar nulos, true -> IS NULL, false -> IS NOT NULL
 * @property {string[]} filterOptions.bool - Array de propiedades a filtrar solo por un valor booleano true o false
 */

/**
 * Devuelve un objeto de filtros para Yii.
 * @param {object} values Filtros.
 * @param {FilterOptions} filterOptions Opciones para construir los filtros.
 */
export function buildFilters(values, filterOptions) {
    const allowEmpty = filterOptions.allowEmpty ?? false
    const filters = {}
    for (const key in values) {
        const value = values[key]
        if (value === undefined || value === null) continue
        if (allowEmpty || value !== '') {
            if ((filterOptions.equal || []).includes(key)) {
                filters[key] = value
            } else if ((filterOptions.contain || []).includes(key)) {
                filters[key] = { ilike: value }
            } else if ((filterOptions.gt || []).includes(key)) {
                filters[key] = { '>': value }
            } else if ((filterOptions.lt || []).includes(key)) {
                filters[key] = { '<': value }
            } else if ((filterOptions.in || []).includes(key)) {
                filters[key] = { in: value }
            } else if ((filterOptions.nin || []).includes(key)) {
                filters[key] = { nin: value }
            } else if ((filterOptions.is || []).includes(key)) {
                filters[key] = { is: value }
            } else if ((filterOptions.isnt || []).includes(key)) {
                filters[key] = { isnt: value }
            } else if ((filterOptions.betweenDate || []).includes(key)) {
                if (Array.isArray(value) && value.length > 0) {
                    const dateIni = formatDate(value[0], 'Y-m-d')
                    const dateEnd = value.length > 1 ? formatDate(value[1], 'Y-m-d') : dateIni
                    if (dateIni && dateEnd) {
                        filters[key] = { '>': `${dateIni} 00:00:00`, '<': `${dateEnd} 23:59:59` }
                    }
                }
            } else if ((filterOptions.between || []).includes(key)) {
                if (Array.isArray(value) && value.length === 2) {
                    filters[key] = { '>': value[0], '<': value[1] }
                }
            } else if ((filterOptions.null || []).includes(key)) {
                if (value === true) {
                    filters[key] = { is: null }
                } else if (value === false) {
                    filters[key] = { isnt: null }
                }
            } else if ((filterOptions.bool || []).includes(key)) {
                if (typeof value === 'boolean') {
                    filters[key] = value ? 1 : 0
                }
            }
        }
    }
    return filters
}

export function objectToQueryString(obj) {
    const result = {}

    function buildQuery(prefix, value) {
        if (Array.isArray(value)) {
            value.forEach((val, index) => {
                buildQuery(`${prefix}[${index}]`, val)
            })
        } else if (typeof value === 'object' && value !== null) {
            for (const key in value) {
                buildQuery(`${prefix}[${key}]`, value[key])
            }
        } else {
            result[prefix] = value
        }
    }

    for (const key in obj) {
        buildQuery(key, obj[key])
    }

    return result
}
