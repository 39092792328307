export default function SortAlphaUpAlt(props) {
    return (
        <svg width='1em' height='1em' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M5 14V2M5 2L1.5 6M5 2L8.5 6M10.5 2H14.5L10.5 7H14.5M10.5 14L10.9 13M14.5 14L14.1 13M10.9 13L12.5 9L14.1 13M10.9 13H14.1'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
